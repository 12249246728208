.site-footer {
  .site-footer-contact {
    .site-footer-contact__menu {
      .site-footer-contact__item {
        width: 50%;
      }
      .site-footer-contact__item--phone {
        a.site-footer-contact__link {
          text-align: right;
        }
      }
      .site-footer-contact__item {
        .site-footer-contact__link {
          font-weight: bold;
        }
      }
    }
  }
  .site-footer__main {
    padding-right: 15px;
    padding-left: 15px;
    div.site-footer__column:nth-child(2) {
      width: 29%;
      .menu-reference {
        .menu--lvl-1 {
          .menu__item--lvl-1 {
            a {
              letter-spacing: 0em;
            }
          }
        }
      }
      @include breakpoint($medium-range-max) {
        @include breakpoint($medium-range-min) {
          width: 25%;
        }
      }
      .menu__item {
        @include breakpoint($medium-up) {
          width: 100%;
        }
        @include breakpoint($large-only) {
          width: 44%;
        }
        margin-bottom: 10px;
      }
    }
    .site-footer__column {
      width: 28%;
      padding: 0 15px;
      .site-email-signup {
        .site-email-signup__header {
          font-weight: bold;
        }
        &__form {
          .site-email-signup__field {
            width: 40%;
            height: 30px;
            @include breakpoint($medium-range-max) {
              @include breakpoint($medium-range-min) {
                width: 59%;
              }
            }
          }
        }
      }
      .site-footer-social-links {
        .site-footer-social-links__header {
          font-weight: bold;
        }
      }
    }
    .site-footer__column:nth-child(4) {
      border-right: none;
      padding-right: 0px;
      width: 32%;
    }
    .site-footer__column:nth-child(1) {
      width: 29.5%;
      @include breakpoint($medium-range-max) {
        @include breakpoint($medium-range-min) {
          width: 28%;
        }
      }
    }
    .site-footer__column:nth-child(3) {
      width: 23%;
      padding-right: 0px;
    }
    .site-email-signup__submit {
      width: 115px;
      padding: 0 5px;
    }
    .trustmark-logo {
      img {
        width: 150px;
        height: 150px;
        @include breakpoint($medium-range-max) {
          @include breakpoint($medium-range-min) {
            width: auto;
            height: auto;
          }
        }
      }
    }
  }
}

.site-nav {
  .menu--lvl-1 {
    li.menu__item--lvl-1 {
      .menu__link--lvl-1 {
        font-weight: bold;
      }
      .menu--lvl-2 {
        li.menu__item--lvl-2 {
          .menu__link--lvl-2 {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.gtl__steps {
  .product-grid__item {
    height: 450px !important;
  }
}

.strobing_gtl {
  .product_collection_hero__content {
    overflow: visible;
  }
}

.section-store-locator {
  .store-locator__links-container {
    .store-locator__dutyfree {
      display: none;
    }
  }
}

.site-utils {
  .site-utils__nav {
    .menu--lvl-1 {
      .menu__link--lvl-1 {
        padding: 0 1px;
        margin-top: 10px;
      }
    }
  }
  .site-utils__links {
    .site-utils__right {
      .site-utils__menu {
        .site-utils__item--account {
          .site-utils__dropdown--account {
            .account-links {
              .account-links__welcome {
                .account-links__name {
                  word-wrap: break-word;
                }
              }
            }
          }
        }
      }
    }
  }
}

.account__section {
  .account-page__content {
    .account-profile {
      .section-content {
        .account-profile__email {
          word-wrap: break-word;
        }
      }
      .section-header__link {
        bottom: 45px;
      }
    }
  }
  .account-utilities {
    .sidebar-menu {
      .sidebar-menu__item {
        letter-spacing: 0.19em;
      }
    }
  }
}

.sign-in {
  .registration {
    #registration__form {
      .registration__password-container {
        .registration__show-pass {
          margin: 5px 0 0 5px;
        }
      }
    }
  }
}

.tiny-waitlist-overlay {
  #waitlist {
    .field {
      .email_input {
        float: left;
        margin-right: 15px;
      }
      .align-r {
        margin-top: 1px;
      }
    }
  }
}

.makeup-lessons-landing {
  .row {
    .makeup-lessons-items {
      .makeup-lessons-item__wrap {
        @include breakpoint($medium-range-max) {
          @include breakpoint($medium-range-min) {
            height: 300px;
          }
        }
        .makeup-lessons-items__lessons {
          @include breakpoint($medium-range-max) {
            @include breakpoint($medium-range-min) {
              bottom: 25px;
            }
          }
          .makeup-lessons-item {
            .makeup-lessons-item__lesson {
              a.button {
                @include breakpoint($medium-range-max) {
                  @include breakpoint($medium-range-min) {
                    padding: 0.75em 0.4em;
                  }
                }
              }
            }
            .makeup-lessons-item__lesson.is-video {
              a.button {
                @include breakpoint($medium-range-max) {
                  @include breakpoint($medium-range-min) {
                    padding-right: 10px;
                    padding-left: 33px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.makeup-lesson-page__slide {
  .makeup-lesson__step-info {
    .makeup-lesson__step-header {
      .makeup-lesson__step-name {
        padding-top: 8px;
        line-height: 26px;
        letter-spacing: 0px;
      }
    }
  }
}

.customer-service-landing {
  .customer-service-section {
    a.customer-service-landing-block__link {
      @include breakpoint($medium-range-max) {
        @include breakpoint($medium-range-min) {
          bottom: 0 !important;
        }
      }
    }
  }
}

.customer-service-quick-info {
  .customer-service-quick-info__list {
    .customer-service-quick-info__list-item {
      width: 40%;
      margin-left: 5%;
    }
  }
}

.customer-service-menu {
  .menu--lvl-1 {
    .menu__list--lvl-1 {
      .menu__item--lvl-1 {
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        a {
          display: initial;
          line-height: 1em;
        }
        .menu--lvl-2 {
          .menu__list--lvl-2 {
            .menu__item--lvl-2 {
              margin-top: 10px;
              .menu__link {
                line-height: 0em;
              }
            }
          }
        }
      }
    }
  }
}

.site-content {
  .customer-service-section {
    .customer-service-contact-form__privacy_policy {
      input[type='checkbox'].error ~ label {
        color: #ff0000;
      }
    }
    #contact-about-content {
      .customer-service-contact-form__categories {
        @include breakpoint($medium-range-max) {
          li {
            font-size: 10px;
            height: auto;
            letter-spacing: 1.4px;
            &#category-service {
              padding: 1em 4px;
            }
          }
        }
      }
    }
  }
  .search-form__results-count {
    display: none !important;
  }
  #search-wrapper {
    #product-results {
      #summary {
        p:first-child {
          display: none;
        }
      }
    }
  }
}

.quickshop,
.spp {
  .product {
    .product__social {
      .icon-twitter,
      .icon-pinterest {
        display: none;
      }
    }
  }
}

.mpp {
  .mpp__filters {
    .product-filter__filter {
      letter-spacing: 0.1em;
      padding: 0 8px;
    }
  }
}

.site-header__addtobag {
  .site-header__addtobag-cell.site-header__addtobag-cell--product-info {
    width: 47%;
    .site-header__addtobag__product-info {
      .product_brief__header {
        letter-spacing: 0.1em;
      }
    }
  }
  .shade-picker__color-name {
    width: 240px;
  }
}

.spp {
  .product__details {
    .product__delivery-price {
      font-size: 12px;
      padding: 30px 0 30px 0;
      margin: 40px 0 0 0;
      border-top: 1px solid $color-black;
      border-bottom: 1px solid $color-black;
    }
  }
}

.foundation-finder {
  .foundation-finder__footer {
    .foundation-finder__tips {
      float: none;
      width: 100%;
    }
    .foundation-finder__chat {
      display: none;
    }
  }
  .foundation-finder__filters {
    .product-filter {
      .product-filter__filter {
        padding: 0 6px;
        letter-spacing: 0.01em;
      }
      .product-filter__label {
        font-size: 16px;
      }
    }
  }
}

.cart_overlay {
  .cart-confirm-wrapper {
    .subtotal {
      .right-copy {
        .sub_t_text {
          margin-right: 15px;
        }
        .sub_t {
          width: 120px;
          .sub_num {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.popup-offer-cbox {
  #cboxLoadedContent {
    height: auto !important;
  }
}

.makeup-lesson__step {
  .makeup-lesson__step-product-grid {
    .slick-slide {
      .product-grid__item {
        height: 100% !important;
      }
    }
  }
}

.appt-book {
  .appointment-select {
    .appointments-container {
      .book-appt-container {
        .appt-book-mobile {
          font-size: 15px;
        }
      }
    }
  }
}

.offer-code-panel {
  .offer_code_form_container {
    em#one-offer-only {
      span {
        a {
          margin-top: 15px;
        }
      }
    }
  }
}

.site-header {
  &__nav {
    @include breakpoint($medium-up) {
      .site-header__hr {
        background-color: $color-black;
      }
    }
  }
}

.site-utils {
  &__links {
    .site-utils__menu {
      .site-utils__item {
        &--live-chat {
          display: none;
        }
      }
    }
  }
}

ul.menu__list.menu__list--lvl-1 {
  li {
    margin-left: 8px;
  }
}

.site-utils {
  .site-utils__links {
    .site-utils__nav.site-nav--sticky {
      .menu--lvl-1 {
        .menu__link--lvl-1 {
          margin-top: 0px;
          font-size: 12px !important;
        }
      }
    }
  }
}

.site-nav {
  .menu__item--lvl-2 {
    width: 19%;
  }
}
/* Power Reviews */

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-helpful {
        &-yes {
          .pr-helpful-count {
            &:before {
              content: 'Да';
            }
          }
        }
        &-no {
          .pr-helpful-count {
            &:before {
              content: 'Нет';
            }
          }
        }
      }
    }
    .pr-snippet-stars-reco-inline {
      .pr-snippet-stars-reco-reco {
        .pr-reco-to-friend-message {
          text-indent: 45px;
        }
      }
    }
  }
}

#pr-war-form {
  .pr-media_image-form-group,
  .pr-media_videourl-form-group {
    label {
      width: 235px !important;
    }
  }
  .tag-group {
    .input-add-tag {
      width: 86%;
    }
  }
}

.spp {
  .product__details {
    .shipping-cost-widget {
      .shipping-cost {
        p:nth-of-type(2) {
          span:nth-child(1) {
            width: 140%;
            float: left;
          }
        }
      }
    }
    a.shipping_calc {
      display: block;
      text-decoration: underline;
      text-transform: uppercase;
      cursor: pointer;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.15em;
      padding-top: 20px;
    }
  }
}

#ship-calc-overlay {
  .country-container {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    input,
    select {
      width: 100%;
      float: left;
      text-transform: uppercase;
    }
    span.label-content {
      text-transform: uppercase;
    }
    select {
      height: 33px;
    }
  }
  .calculation-result {
    .shipping-type {
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
  .close-popup {
    padding: 8px 10px;
    background: rgb(0, 0, 0);
    margin-left: 15px;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    cursor: pointer;
    font-weight: normal;
    text-decoration: none;
    color: #fff;
    line-height: 14px;
    float: right;
  }
  .missing_region,
  .missing_city {
    color: red !important;
  }
  a.close-popup:hover {
    color: #fff;
    background: #ff4661;
    border: none;
  }
}

.shipping-cost-widget {
  padding-top: 15px;
  margin-bottom: -39px;
  .shipping-price {
    margin-right: 5px;
    padding-bottom: 5px;
  }
  .shipping-title {
    font-weight: bold;
    margin-bottom: 12px;
  }
  .country-container {
    display: block;
    margin-bottom: -23px;
  }
}

#shipping_calculator {
  .calculation-result {
    clear: both;
  }
  .submit_popup {
    margin-top: 15px;
  }
}

#cboxWrapper {
  .js-popup-offer-submit {
    margin-top: 14px;
  }
}

.js-popup-offer {
  .popup-offer__sign-up {
    .popup-offer__body p {
      line-height: 1.45em;
    }
  }
}

.lp_radio_button {
  input {
    display: none;
  }
}

#footer_sticky_chat {
  min-height: 65px;
}

.shaded_details {
  .shade-picker__color-name {
    .js-sku-menu-wrapper {
      a.selectBox {
        padding-left: 0px !important;
      }
    }
  }
}

.js-sticky-header {
  .js-site-header-sticky {
    .site-utils__right {
      position: relative;
      bottom: 12px;
    }
    .site-utils__inner {
      max-width: 100%;
    }
  }
}

.slick-next,
.slick-prev {
  font-size: 0 !important;
}

.slick-dots li {
  .offers-vip__button,
  button {
    font-size: 0 !important;
  }
}

.page-product {
  .spp-product-layout__content-item-heading {
    font-size: 65px;
  }
}
