.checkout {
  &__panel-title {
    margin-bottom: 1.5em;
  }
  &__content {
    .cart-header {
      .qty {
        width: 23%;
      }
      .price {
        width: 29%;
      }
    }
    .cart-item {
      &__qty {
        width: 23%;
      }
      &__price {
        width: 20%;
      }
      &__total {
        width: 19%;
      }
    }
    .total_column.sub__total {
      margin: 0.8em 0;
      float: right;
    }
  }
  .viewcart-buttons-panel {
    .continue-buttons {
      a.disabled {
        background-color: $color-gray-light;
        cursor: default;
      }
    }
  }
  .cross_sell_container {
    .cart-item__desc-info {
      .color {
        margin-bottom: 1em;
      }
    }
    .add_button {
      margin: 0.8em 0;
    }
  }
  &.samples-page {
    .product {
      height: 485px;
      word-wrap: break-word;
      &.first {
        margin-left: 0;
      }
      &.last {
        margin-right: 0;
      }
    }
    .samples-buttons.bottom {
      display: block;
    }
  }
  ol.checkout-progress li {
    float: left;
    margin-left: 1.5em;
    width: 12.5em;
    list-style-type: decimal;
    margin-top: 1.5em;
  }
  //Control directive to highlight checkout breadcrumb
  $checkout: (shipping, review, billing, confirm);
  @each $value in $checkout {
    &.#{$value} {
      .checkout-progress__#{$value} {
        font-weight: bold;
      }
    }
  }
  #address_form_container {
    .phone_label_container {
      margin-bottom: 10px;
    }
  }
  .checkout-header {
    margin: 1.5em 0;
  }
  .checkout-buttons-container {
    margin-bottom: 7em;
    .btn {
      float: right;
    }
  }
  .product.deactivate {
    .sample-select-button {
      background: $color-gray-light;
    }
  }
  .transaction-panel-details {
    margin-top: 2em;
  }
  address {
    font-style: normal;
  }
  #shipping-page-info-panel {
    .shipping-address-info {
      .address-container {
        float: left;
        margin: 0 3em 0.8em 0;
        width: 15em;
      }
      .address_controls {
        float: left;
        #choose-address {
          margin-top: 0.8em;
          width: 24em;
        }
      }
    }
    .checkout-buttons-container {
      margin-bottom: 0;
    }
  }
  .giftwrap-info {
    margin-bottom: 1.5em;
    #gift-options {
      .shipping-panel__title {
        line-height: 18px;
        text-indent: 0;
        margin: 4px 0 10px 0;
        padding-bottom: 17px;
        border-bottom: 1px solid $color-black;
        background-color: $color-white;
      }
    }
  }
  #emailpromo-info-panel {
    float: left;
    margin-top: 20px;
  }
  .input_country_id {
    margin-bottom: 20px;
  }
  .promotions-container {
    .newsletter-info {
      h3.newsletter-info__header {
        margin-left: -21px;
      }
      .newsletter-info__text--new {
        margin-bottom: 0;
      }
      .newsletter-info__text--new,
      .newsletter-info__text--provid {
        margin-left: auto;
      }
      .newsletter-info__item {
        margin-left: 0px;
      }
    }
  }
  .checkout-terms {
    label.error {
      color: $color-red;
    }
    h3,
    &__header {
      @include h8;
      text-indent: $account-indent;
      margin: 0 0 20px -21px;
      padding-bottom: 14px;
      border-bottom: $account-alt-heading-separator;
      clear: both;
    }
  }
  .checkout-buttons-container {
    margin-top: 2em;
  }
}

#address_form_container {
  .address-overlay {
    &-billing,
    &-shipping {
      .selectbox {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}

#confirm {
  #confirmation-email {
    margin-bottom: 0.8em;
  }
  .return-link-container {
    margin-bottom: 2em;
  }
}

.order-confirmation {
  .account-utilities {
    display: none;
  }
  .order-totals {
    width: 55%;
    float: right;
    th,
    tr,
    td {
      border: 0;
    }
    td {
      text-align: right;
    }
  }
  .cart-item:last-child {
    border-bottom: 1px solid $color-light-gray;
    padding-top: 20px;
  }
  .order-details-page {
    &__content .cart-item {
      &__thumb {
        width: 16%;
        padding-right: 0;
        margin-right: 10px;
      }
      &__desc {
        width: 29%;
        float: left;
        margin-left: 10px;
      }
      &__price {
        width: 21%;
        @include breakpoint($medium-range-max) {
          @include breakpoint($medium-range-min) {
            width: 19.5%;
          }
        }
      }
      &__qty {
        width: 20%;
        clear: none;
        float: left;
        margin: 0px;
      }
      &__total {
        width: 10%;
        float: left;
        text-align: right;
      }
    }
    .sample {
      .cart-item__qty-label {
        display: inline;
        visibility: hidden;
      }
    }
  }
}

.checkout__sidebar {
  .account-utilities {
    &__section {
      background: $color-gray-background;
      padding: 16px 20px;
    }
    &__header {
      font-family: $brandon-text-bold;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      text-indent: 0;
      margin: 4px 0 10px 0;
      padding-bottom: 17px;
      border-bottom: 1px solid $color-black;
    }
  }
  #links-panel {
    margin-top: 6px;
    border: 5px solid $color-nude;
    padding: 15px 15px 20px 15px;
    background: $color-white;
    .link {
      text-decoration: none;
      display: block;
      border: 0;
      line-height: normal;
    }
  }
  #order-summary-panel {
    .tax.label {
      line-height: 40px;
    }
  }
  #offer-code-panel {
    #one-offer-only {
      font-style: normal;
      margin: 18px 0 14px 0;
      display: block;
    }
  }
}

.payment-type-panel {
  margin-top: 1em;
}

#payment-info-panel {
  margin-bottom: 1em;
}

.payment-properties {
  .payment-group-options-cc {
    margin: 1em 0;
    .payment-item-wrapper {
      margin-bottom: 20px;
      input[type='radio'] ~ label::after,
      input[type='radio'] ~ label::before {
        top: 10px;
      }
      input {
        margin-right: 5px;
      }
      label {
        margin-bottom: 0;
        span {
          height: 35px;
          line-height: 35px;
          display: inline;
          margin-right: 10px;
        }
        img {
          height: 35px;
        }
        ul li {
          display: inline-block;
        }
      }
      .opt_desc {
        display: block;
        font-weight: normal;
        margin-left: 25px;
        width: 100%;
        font-style: italic;
      }
    }
  }
  .payment-item-wrapper {
    img {
      margin-right: 10px;
    }
  }
}

.cart-item__product-subline {
  a {
    color: #000;
    text-decoration: none;
  }
}

.product-info__sub_line {
  a {
    color: #000;
    text-decoration: none;
  }
}

.checkout__content {
  .cart-item {
    &__total {
      @include breakpoint($medium-range-max) {
        @include breakpoint($medium-range-min) {
          width: 19%;
        }
      }
    }
    &__desc {
      word-wrap: break-word;
      padding-right: 10px;
      @include breakpoint($medium-range-max) {
        @include breakpoint($medium-range-min) {
          width: 37%;
        }
      }
    }
    &__qty {
      @include breakpoint($medium-range-max) {
        @include breakpoint($medium-range-min) {
          width: 24.5%;
        }
      }
    }
    &__price {
      @include breakpoint($medium-range-max) {
        @include breakpoint($medium-range-min) {
          width: 19%;
        }
      }
    }
  }
}

.viewcart-buttons-panel {
  .continue-buttons {
    @include breakpoint($large-range-max) {
      @include breakpoint($medium-up) {
        float: right;
        margin-top: 5px;
      }
    }
    @include breakpoint($medium-range-max) {
      @include breakpoint($medium-range-min) {
        width: 100%;
      }
    }
    .continue-checkout {
      @include breakpoint($medium-range-max) {
        @include breakpoint($medium-range-min) {
          width: 100%;
        }
      }
    }
  }
  .continue-shopping {
    @include breakpoint($medium-range-max) {
      @include breakpoint($medium-range-min) {
        width: 100%;
      }
    }
  }
}

.recommended-products-panel__content {
  .recommended-product-items {
    .recommended-item {
      margin: 0px 3%;
      width: 27.33%;
      .add_button {
        .btn {
          @include breakpoint($medium-range-max) {
            @include breakpoint($medium-range-min) {
              letter-spacing: 0.1px;
              width: 175px;
            }
          }
        }
      }
    }
    .product_name {
      @include breakpoint($large-range-max) {
        @include breakpoint($medium-up) {
          min-height: 125px;
        }
      }
    }
  }
}

.confirm {
  .checkout__content {
    @include breakpoint($large-range-max) {
      @include breakpoint($medium-up) {
        width: 61%;
        margin-left: 15px;
      }
    }
  }
}

.cart_overlay {
  .full_view {
    .subtotal {
      .sub_t {
        .sub_num {
          float: left;
          margin-left: 8px;
        }
      }
    }
  }
}

.review {
  #checkout_review {
    .checkout__content {
      .checkout-buttons-container {
        text-align: right;
        .checkout-buttons.disabled {
          background-color: $color-gray-light;
          cursor: default;
        }
      }
      .payment-properties {
        margin-bottom: 15px;
      }
    }
  }
}

.shipping {
  #shipping-page-info-panel {
    .fs.address {
      .form_element {
        margin-bottom: 20px;
        input[type='text'],
        input[type='password'],
        input[type='tel'],
        input[type='number'] {
          margin-bottom: 0px;
        }
      }
    }
  }
  #delivery-options {
    margin-top: 1.5em;
  }
}

#checkout_shipping {
  .privacy-policy {
    &__fieldset {
      margin-bottom: 20px;
    }
    &__required {
      display: none;
    }
  }
}

.order-totals__section {
  table {
    font-size: 13px;
  }
}

.cart-items {
  .cart-item__desc-info {
    .color {
      .swatch {
        width: 22px !important;
      }
    }
  }
}

.checkout-co-enabled {
  .pg_wrapper {
    .left {
      .viewcart-header {
        margin: 0;
        .checkout__panel-title {
          font-size: 30px !important;
        }
      }
      .cart-samples-panel {
        .checkout {
          &__panel-title {
            font-size: 14px !important;
            font-weight: bold;
            letter-spacing: 0.1em;
          }
        }
      }
      .cart-item {
        .qty_select {
          .selectBox-arrow {
            line-height: 2.5;
          }
        }
      }
    }
    .right {
      .order-summary-panel,
      .offer-code-panel,
      .links-panel {
        &__title {
          font-size: 14px !important;
          margin-top: 20px;
          font-weight: bold;
          letter-spacing: 0.1em;
        }
      }
      .offer-code-panel {
        &__title,
        &__content {
          margin-top: 0;
        }
      }
      .links-panel {
        &__title {
          margin-bottom: 0;
          margin-top: 0;
        }
      }
    }
    &.checkout {
      #recommended-products-panel {
        .recommended-products-panel {
          &__title {
            font-size: 14px !important;
          }
        }
        .content {
          .recommended-product-items {
            .recommended-item {
              .add_button {
                .btn {
                  white-space: normal;
                  max-width: 100%;
                  line-height: 1.5;
                  height: 50px;
                }
              }
              .description {
                .info {
                  .product_name {
                    a {
                      font-size: 14px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &#signin {
    .pg_wrapper {
      .sign-in-page {
        .account {
          &__new-account {
            input[type='password'] {
              margin-bottom: 20px;
            }
            .info-for-left {
              .label::before {
                margin-top: 4px;
              }
            }
          }
        }
        .new-account {
          &__item {
            position: relative;
          }
        }
        &.checkout {
          #new-or-returning-links {
            .new-customer,
            .returning-customer {
              border-bottom: 1px solid $color-gray;
              &.active {
                border-bottom: 2px solid $color-black;
              }
            }
          }
        }
      }
      .new-account {
        &__item {
          position: relative;
        }
      }
    }
  }
  input[type='submit'] {
    font-size: 12px !important;
  }
}
