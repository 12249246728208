.product-brief {
  &__product-info-wrapper {
    .product-brief {
      &__price {
        &.loyalty-enabled-pc-hidden {
          .product-sku-price {
            &__trow {
              width: 100%;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  &__price {
    &.discount_price {
      .product-sku-price {
        &__trow {
          margin-left: 0;
          .amount-loyalty {
            width: auto;
          }
        }
      }
    }
    .price {
      display: block;
    }
    .product-sku-price {
      &__amount {
        font-size: 22px;
      }
      &__trow {
        display: block;
        color: $color-gray-darker;
        font-weight: 100;
        margin-left: 10%;
        &.tier1 {
          margin-bottom: 10px;
        }
        &.active {
          color: $color-black;
          font-weight: 700;
        }
      }
      &__tcol {
        display: inline-block;
        &.amount-loyalty {
          width: 30%;
          text-align: left;
        }
        &.description-loyalty {
          width: 65%;
          text-align: left;
          white-space: nowrap;
        }
      }
    }
  }
}
.checkout-co-enabled {
  .checkout {
    #recommended-products-panel {
      .content {
        .recommended-product-items {
          .recommended-item {
            .info {
              .amount-loyalty {
                .formatted_price {
                  font-size: inherit;
                  text-align: left;
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
.cart-item {
  &__desc-info {
    .product-sku-price {
      &__amount {
        font-size: 22px;
      }
      &__trow {
        display: block;
        color: $color-gray-darker;
        font-weight: 100;
        font-size: 14px;
        &.cloned {
          margin-top: 20px;
        }
        &.tier1 {
          margin-bottom: 0;
        }
        &.active {
          color: $color-black;
          font-weight: 700;
        }
      }
      &__tcol {
        display: inline-block;
        &.amount-loyalty {
          width: 36%;
          text-align: left;
        }
        &.description-loyalty {
          width: 60%;
          text-align: left;
          white-space: nowrap;
        }
      }
    }
  }
}
.back-link-wrapper {
  text-align: center;
  font-size: 8px;
  padding-left: 5%;
}
.loyalty-content {
  &__header {
    padding-top: 15px;
    margin-bottom: 0;
    font-size: 17px;
    text-align: center;
  }
  display: flex;
  flex-direction: row;
  &__column {
    flex-direction: row;
    padding: 5px;
    &__image {
      img {
        width: 175px;
        height: 175px;
      }
    }
    &__text {
      text-align: center;
      padding: 10px;
      font-weight: 100;
      margin: 0;
      padding-left: 10px;
    }
  }
}
.loyalty-account {
  &__container {
    display: flex;
    padding: 0 10%;
    flex-direction: column;
  }
  &__row {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }
  &__cell {
    margin: 10px;
    display: flex;
    width: 100%;
    align-items: center;
  }
  &__header {
    text-align: center;
  }
  &__subheader {
    padding: 10px;
    text-transform: uppercase;
    font-weight: 100;
    font-size: 15px;
    margin: 0;
    padding-left: 10px;
  }
  &__img {
    width: 65px;
    height: 65px;
  }
}

.discount_price {
  .description-loyalty,
  .product-base-price-desc {
    display: none;
  }
}

.product-full {
  &__data-price {
    border-bottom: $color-very-light-pink solid 1px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    .product-full-price {
      &__price {
        &[data-is-discountable='0'] {
          color: $color-black;
        }
        width: 100%;
        display: block;
        font-size: 15px;
        color: $color-gray-darker;
        .price-loyalty {
          width: 20%;
          display: inline-block;
        }
        .product-base-price-desc {
          width: 65%;
          display: inline-block;
        }
        &.active {
          font-weight: 700;
          color: $color-black;
        }
        &.discount_price {
          .product-base-price-desc {
            display: none;
          }
          .old_price {
            color: $color-black;
          }
        }
      }
    }
  }
}
.product-info-wrapper {
  .product-info {
    display: flex;
  }
  .product-brief {
    &__price {
      padding-right: 8%;
      white-space: nowrap;
    }
  }
  .product-sku-price {
    &__trow {
      &.active {
        display: flex;
      }
    }
  }
  .favorite-actions {
    text-align: center;
  }
}
.account-bestsellers {
  &__title {
    clear: both;
    text-align: center;
  }
  &__content {
    .mpp-grid {
      &__item {
        display: flex;
      }
      .product-brief {
        &__shades {
          display: none;
        }
        &__flag {
          display: none;
        }
      }
      .product-sku-price {
        &__trow {
          display: none;
          &.active {
            display: flex;
            text-align: center;
            padding-left: 30%;
            .amount-loyalty {
              width: unset;
            }
          }
          .description-loyalty {
            display: none;
          }
        }
      }
    }
    .mpp-container {
      &__content {
        position: unset;
      }
    }
  }
}
.search-form {
  .product {
    &__price {
      width: 180px;
    }
  }
}
.device-mobile {
  .product-info-wrapper {
    .product-info {
      display: block;
      .product-sku-price {
        &__trow {
          margin: 5px 0 0 0;
        }
      }
    }
  }
  .product-full {
    &__data-price {
      a {
        padding-left: 20px;
      }
      .product-full-price {
        &__price {
          padding-left: 20px;
        }
      }
    }
  }
  .product-thumb--price,
  .price-loyalty,
  .price,
  .discount-old-price,
  .discount-price,
  .cart-item__total,
  .amount-loyalty,
  .subtotal.value,
  .total.value {
    font-family: $font--helvetica-neue;
  }
  .favorites__item {
    .product-sku-price {
      &__trow {
        font-family: $font--helvetica-neue;
      }
    }
  }
  .product-brief {
    &__price {
      .product-sku-price {
        &__trow {
          font-weight: 400;
        }
      }
      .active {
        .amount-loyalty {
          font-weight: 700;
          .price {
            font-weight: 700 !important;
          }
        }
        .description-loyalty {
          font-weight: 700;
        }
      }
    }
  }
}
.site-utils {
  &__item & {
    &__navigation--loyalty {
      .home_gnav_loyalty {
        font-size: 15px;
        letter-spacing: 1px;
        height: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}
.favorites-page {
  &__content {
    .product-brief {
      &__price {
        .product-sku-price {
          &__trow {
            display: block;
            color: $color-black;
            font-weight: 700;
          }
        }
      }
    }
  }
}
