.enhanced-delivery-page {
  .viewcart-items {
    #review-address-info {
      .transaction-details,
      .checkout__subtitle,
      .shipping-panel__title {
        display: none;
      }
      .gift-wrap__title {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .gift_container {
        display: flex;
        .gift-image {
          height: 100%;
          padding-#{$rdirection}: 20px;
          width: 40%;
        }
      }
    }
  }
  #gift-options-wrapper {
    margin: 0 -8px;
    padding: 8px 8px 16px;
    .gift-options-img {
      float: $ldirection;
      width: 25%;
    }
    .gift-options-item {
      float: $ldirection;
      width: 75%;
      .giftwrap-switch {
        margin: 15px 0 10px;
      }
    }
    .gift-wrap__text-yes,
    .gift-wrap__text-no {
      #{$ldirection}: 16px;
      font-size: 12px;
      position: relative;
      top: 4px;
    }
  }
  #giftwrap-info-panel #gift-options {
    border-bottom: 1px solid;
    border-top: 1px solid;
    margin-top: 20px;
    padding: 0 15px;
    .giftwrap-switch {
      display: inline-block;
      position: relative;
      vertical-align: middle;
      .label-content {
        opacity: 0;
      }
      .giftwrap-slider {
        @include border-radius(10px);
        background: $color-nobel;
        cursor: pointer;
        height: 16px;
        width: 40px;
        &::before {
          @include border-radius(10px);
          @include box-shadow(0 1px 10px $color-white-shade);
          #{$ldirection}: 0;
          background: $color-white;
          content: '';
          display: block;
          height: 20px;
          position: absolute;
          top: -2px;
          transition: all ease 0.3s;
          width: 20px;
        }
      }
      input[type='checkbox'] {
        &:checked ~ .giftwrap-slider {
          background: $color-green-light;
          &::before {
            #{$ldirection}: 20px;
          }
        }
      }
    }
    .gift-options-item {
      .checkboxes {
        .giftwrap-switch-label {
          label {
            position: relative;
          }
        }
      }
    }
    .checkboxes {
      label {
        #{$ldirection}: 0;
        height: 100%;
        position: absolute;
        text-indent: -9999px;
        top: 0;
        width: 100%;
      }
    }
    .giftwrap-panel__title {
      border: 0;
      margin: 14px 0;
      padding: 0;
      .plus {
        @include icon('plus');
      }
      .minus {
        @include icon('minus');
      }
      .giftwrap-icon {
        cursor: pointer;
        float: $rdirection;
        margin-#{$rdirection}: 2px;
        &::before {
          font-weight: 700;
        }
      }
    }
  }
  .shipping-panel {
    .giftwrap-content {
      display: none;
    }
  }
  #checkout_shipping #gift-options .checkboxes {
    padding: 0;
  }
}
