.mpp-filter-set {
  .mpp-filter-set__filters {
    padding: 0 17px;
  }
  .mpp-filter-set__header {
    .mpp-filter-title {
      padding: 20px 0 0 17px;
    }
  }
  .mpp-filter-set__title {
    padding-left: 17px;
  }
  .mpp-filter-set__footer--cta {
    padding: 15px 17px;
  }
}

.site-footer-email-signup {
  a {
    display: inline;
    color: $color-pink;
  }
}

.checkout-co-enabled {
  &#signin {
    .section-head {
      &__header {
        font-size: 30px !important;
      }
    }
    .account {
      &__new-account,
      &__return-user {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
    .label {
      letter-spacing: 0.04em;
      font-size: 12px;
      line-height: 1.5;
      &:before {
        vertical-align: top;
        padding-top: 3px;
        margin-#{$rdirection}: 15px;
      }
    }
    .social_login {
      @include breakpoint($medium-up) {
        padding: 0 80px;
      }
      .social_login_v2 {
        &__delimeter-horizontal {
          text-align: center;
          text-transform: uppercase;
          margin: 20px 0 10px;
          &:before {
            background: $color-gray-dark;
          }
        }
        &__delimeter-label {
          color: $color-gray-dark;
          font-size: 10px;
          font-weight: bold;
          letter-spacing: 0.04em;
        }
        &__social-login-container {
          text-align: center;
          margin-bottom: 20px;
          .social-login-container {
            &__social-network {
              width: 100%;
              margin: 5px 0;
              @include breakpoint($medium-up) {
                margin: 10px 0;
              }
              .social-login-container {
                &__social-network-button {
                  width: 100%;
                  font-size: 12px !important;
                  padding: 13px;
                  line-height: 2;
                  border-radius: 0;
                  overflow: hidden;
                  &_facebook {
                    background-color: $color-facebook;
                    background-position: 5px 1px !important;
                    &:hover {
                      background-color: $color-facebook !important;
                    }
                  }
                  &_vkontakte {
                    background-color: $color-vkontakte;
                    &:hover {
                      background-color: $color-vkontakte !important;
                    }
                  }
                  .social-login-container {
                    &__social-network-button-label {
                      display: block;
                      position: initial;
                      #{$ldirection}: 5%;
                      letter-spacing: 0.15em;
                      font-weight: bold;
                    }
                  }
                }
                &__social-network-blocker {
                  height: 50px;
                }
              }
            }
          }
        }
      }
      .sign-in-component_ru {
        &__legal-container {
          width: 100%;
        }
      }
    }
    @media only screen and (min-device-width: $tablet) and (max-device-width: $desktop) {
      .new-account {
        &__fieldset {
          .info-for-left {
            top: 38%;
          }
        }
      }
    }
    .info-for-left {
      position: absolute;
      top: 27%;
      #{$rdirection}: 3%;
      input[type='checkbox'] {
        & ~ .label,
        & ~ label {
          &::before {
            content: '';
          }
        }
        &:checked {
          & ~ .label,
          & ~ label {
            &::before {
              content: '';
            }
          }
        }
      }
      .label-content {
        border-bottom: 1px solid $color-black;
      }
    }
    .return-user {
      &__item {
        margin-bottom: 0;
        .return-user {
          &__submit {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &#viewcart {
    .checkout {
      .cart-item {
        .color {
          .swatch {
            font-size: 12px !important;
          }
        }
      }
      #links-panel {
        .link {
          font-size: 12px !important;
        }
      }
    }
  }
}
@include breakpoint($medium-landscape-only) {
  .product-brief {
    $self: &;
    &__product-info-wrapper {
      #{$self} {
        &__price {
          &.pc-hidden {
            display: none !important;
          }
        }
      }
    }
  }
}
@include breakpoint($medium-portrait-only) {
  .product-brief {
    $self: &;
    &__product-info-wrapper {
      #{$self} {
        &__price {
          &.pc-hidden {
            display: block !important;
            text-align: center;
            width: 100%;
          }
        }
      }
    }
  }
}
