$ab-ipad: (width 350px 780px);

.appt-book {
  .appointment-booking-main-collection__header {
    .appointment-booking-main-collection__header-content {
      height: auto !important;
    }
  }
  #appointment-book-sections {
    .appointment-booking-review__services {
      .service {
        padding-top: 30px;
      }
    }
    .appointment-booking-review__account-signup-form {
      .signup__mobile-prefix {
        width: 65px !important;
      }
      .signup__mobile-phone {
        padding-left: 60px !important;
      }
      input {
        font-size: 13px;
      }
    }
    .service-select {
      .book-now-bar {
        .back-to-link {
          display: inline-block;
        }
      }
      .services-container {
        .service {
          @include breakpoint($ab-ipad) {
            .service-information {
              margin-bottom: 38%;
              .service-details {
                .service-title {
                  height: auto;
                  word-break: break-word;
                }
              }
            }
          }
        }
      }
    }
    .appointment-booking-schedule__day-time-container {
      table {
        min-height: 1px;
      }
    }
  }
}

.appointments-page.account__section {
  .appt-book-no-appts-content {
    @include breakpoint($ab-ipad) {
      .pc-hidden {
        display: block !important;
      }
    }
    .no-appts-content {
      width: 55% !important;
      @include breakpoint($ab-ipad) {
        padding-top: 10px !important;
      }
    }
  }
  padding-left: 2% !important;
  padding-right: 2% !important;
}
