#signin {
  .checkout {
    .return-user__item,
    .new-account__item {
      margin-bottom: 10px;
      input[type='text'],
      input[type='password'],
      input[type='email'] {
        width: 100%;
        height: calc(3em - -2px);
        &::placeholder {
          text-transform: uppercase;
        }
      }
      .sign-in-legal-link,
      #forgot-password {
        color: $color-pink;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .account__return-user {
      input[type='text'] {
        margin-top: 0px;
      }
    }
    .account__new-account {
      .newsletter-info {
        &__header,
        .info-link,
        &__text--new,
        &__text--provid,
        .pc_email_promo_terms_container {
          display: none;
        }
        .sign-in-legal-link {
          display: block;
          margin: 0px;
        }
        &__item,
        &__text {
          margin: 0px;
          padding: 0px;
        }
        &__item {
          min-height: 64px;
        }
      }
    }
    .sign-in-page__indent {
      padding-#{$ldirection}: 0px;
    }
    .sign-in-page__container {
      @include swap_direction(margin, 0 auto);
    }
    input[type='submit'] {
      background-color: $color-pink;
      color: $color-white;
      width: 100%;
      padding: 15px;
      height: auto;
      line-height: 1;
      #{$ldirection}: 0px;
    }
    .section-head__header {
      text-indent: 0px;
      border-bottom: 1px solid $color-black;
    }
    .sign-in-page-content-wrapper {
      @include swap_direction(padding, 0 20px);
    }
    .section-head {
      margin-bottom: 25px;
    }
    .pc_email_promotions {
      min-height: 63px;
    }
  }
}
