.enhanced-delivery-page {
  .checkout {
    .column.right.checkout__sidebar {
      @include box-shadow(1px 2px 10px $color-light-gray);
      background: $color-gray-background;
      margin: 0;
      width: 380px;
      .checkout__panel-title,
      .checkout__subtitle {
        color: $color-grey-dark;
        font-family: $bbtext-bold;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.25px;
        line-height: 1.15;
      }
      .customer-service {
        display: none;
      }
      .checkout-buttons-container {
        background: $color-grey-light;
        border-bottom: 1px solid $color-lighter-gray;
        display: block;
        height: 96px;
        margin-bottom: 0;
        padding: 24px 16px 25px;
        input[type='submit'].btn {
          background-color: $color-pink;
          font-size: 12px;
          font-weight: bold;
          height: 48px;
          letter-spacing: 1.25px;
          line-height: 1.4;
          padding: 12px;
          width: 100%;
        }
      }
      .order-summary-panel {
        @include swap_direction(padding, 13px 10px 13px 13px);
        background: $color-white;
        border-bottom: 1px solid $color-grey-border;
        border-top: 1px solid $color-grey-border;
        margin: 0;
        .order-summary-table {
          .shipping-container {
            border-bottom: 1px solid $color-gray-dark;
            clear: both;
            margin-bottom: 16px;
            padding-bottom: 12px;
          }
          .shipping-msg {
            background: $color-grey-standard;
            border: 1px solid $color-gray-dark;
            color: $color-gray-dark;
            display: inline-block;
            font-size: 14px;
            margin: 14px 0 9px;
            max-width: 354px;
            padding: 13px 14.5px;
            text-transform: capitalize;
            width: 100%;
          }
        }
        &__title {
          border-bottom: none;
          color: $color-grey-dark;
          font-family: $bbtext-bold;
          font-size: 14px;
          font-weight: bold;
          margin: 0;
          letter-spacing: 0.25px;
          line-height: 1.35;
          padding: 0;
          text-transform: uppercase;
          width: 100%;
          .title-icon {
            @include icon('plus', 'after', false, false, 14px);
            #{$rdirection}: 7px;
            cursor: pointer;
            float: $rdirection;
            position: absolute;
            &::after {
              #{$ldirection}: 6px;
              bottom: 2px;
              font-weight: bold;
              position: relative;
            }
          }
          &.open {
            .title-icon {
              @include icon('minus', 'after', false, false, 14px);
            }
          }
        }
        .order-summary__content {
          font-size: 12px;
          margin-top: 12px;
          text-transform: capitalize;
          p {
            display: none;
          }
          .ship-label-sec {
            border-bottom: 1px solid $color-grey-shade;
            margin-bottom: 18px;
            padding-bottom: 14px;
            .shipping-label-data {
              background-color: $color-grey-lighter;
              border: 1px solid $color-nobel;
              color: $color-nobel;
              display: block;
              font-size: 12px;
              letter-spacing: 0.25px;
              line-height: 1.4;
              margin-top: 4px;
              padding: 13px 14px;
            }
          }
          .label {
            background: $color-white;
            color: $color-nobel;
            font-weight: normal;
            line-height: 1.45;
            margin: 0 0 6px;
            padding: 0;
            &.tax {
              color: $color-nobel;
              font-size: 12px;
              letter-spacing: 0.25px;
              line-height: 1.4;
            }
            &.total {
              color: $color-grey-dark;
              font-family: $bbtext-bold;
              font-weight: bold;
              font-size: 14px;
              letter-spacing: 0.25px;
              text-transform: uppercase;
            }
          }
          .value {
            background: $color-white;
            color: $color-grey-dark;
            font-weight: bold;
            height: auto;
            line-height: 1.15;
            margin: 0;
            padding: 0;
          }
          .discount-save {
            clear: both;
            margin: 10px 0;
          }
        }
      }
      #order-summary-panel {
        @include swap_direction(padding, 13px 10px 13px 13px);
        .order-summary-panel__title {
          font-size: 14px;
          line-height: 1.15;
        }
      }
      .viewcart-items {
        @include swap_direction(padding, 13px 10px 13px 13px);
        background: $color-white;
        border-bottom: 1px solid $color-grey-border;
        .cart-item {
          border-bottom: none;
          display: flex;
          padding: 10px 0 0;
          .cart-item-price {
            .price + .total {
              padding-#{$ldirection}: 5px;
            }
          }
          .thumb {
            float: $ldirection;
            width: 40%;
            img {
              height: 117px;
              width: 117px;
            }
          }
          .swatch {
            max-width: 22px;
          }
          &__desc-container {
            width: calc(100% - 117px);
            .desc {
              float: none;
              padding: 7px 0 0;
              width: 100%;
            }
            .qty {
              @include swap_direction(padding, 5px 7px 3px 0);
            }
          }
          &__product-name {
            a {
              font-size: 12px;
              font-weight: 700;
              letter-spacing: 0.25px;
              line-height: 1.2;
            }
          }
          &__size {
            color: $color-gray-dark;
            font-size: 12px;
            letter-spacing: 0.25px;
            line-height: 1.7;
          }
          &__price {
            display: flex !important;
            float: none;
            font-size: 12px;
            justify-content: space-between;
            padding: 0;
            width: auto;
            .discount-old-price {
              color: $color-gray-dark;
              width: auto;
            }
            &::before {
              display: none;
            }
            p {
              margin: 0;
            }
          }
          &__total {
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 0.25px;
            line-height: 1.7;
            margin-top: 8px;
            position: static;
            width: auto;
          }
          &__qty {
            color: $color-gray-dark;
            float: none;
            font-size: 12px;
            letter-spacing: 0.25px;
            line-height: 1.34;
            padding: 0;
            text-align: $ldirection;
            text-transform: none;
            width: 100%;
            .samples_discounted_price {
              color: $color-black;
              float: $rdirection;
              font-weight: 700;
            }
          }
          &.sample {
            .cart-item__price {
              display: block !important;
              float: $ldirection;
              &.price_hidden {
                display: none !important;
              }
            }
            .cart-item__total {
              float: $rdirection;
            }
          }
        }
      }
      .offer-code-content {
        background: $color-white;
        border-bottom: 1px solid $color-grey-border;
        margin-bottom: 0;
        padding: 13px 24px;
        .offer-code-panel {
          background: $color-grey-light;
          margin-bottom: 0;
          padding: 14px 16px;
          &__title {
            border-bottom: none;
            font-family: $bbtext-bold;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0.25px;
            line-height: 1.15;
            margin: 0;
            padding-bottom: 0;
            .title-icon {
              @include icon('plus', 'after', false, false, 14px);
              #{$rdirection}: 0;
              cursor: pointer;
              float: $rdirection;
              position: absolute;
              &::after {
                font-weight: bold;
              }
            }
            &.open {
              .title-icon {
                @include icon('minus', 'after', false, false, 14px);
              }
            }
          }
          &#offer-code-panel .offer-code-message {
            display: none;
          }
          .checkout__panel-content {
            margin-top: 0;
            padding-top: 10px;
            .offer_code_box {
              font-size: 12px;
              input[type='text'] {
                &:focus {
                  border-color: $color-black;
                }
              }
            }
            p {
              color: $color-green-light;
              margin: 0 0 10px;
            }
          }
          &#offer-code-panel .offer_code_form_container {
            input {
              font-size: 12px;
              height: 43px;
              text-transform: lowercase;
            }
            em#one-offer-only {
              clear: both;
              font-style: italic;
              margin: 10px 0;
              overflow: hidden;
              text-transform: none;
              span {
                @include display-flex;
                float: none;
                font-weight: bold;
              }
              .continue-shopping {
                border-bottom: 1px solid $color-pink;
                color: $color-pink;
                font-size: 12px;
                font-style: normal;
                letter-spacing: 0.25px;
                margin: 15px 0 0;
                padding-bottom: 0;
                position: relative;
              }
              img {
                padding-top: 10px;
              }
            }
            .btn {
              font-size: 12px;
              font-weight: bold;
              height: 44px;
              letter-spacing: 0.25px;
              margin-top: 13px;
              text-transform: uppercase;
              width: 100%;
            }
          }
        }
      }
      #shipping-panel {
        border: 0;
        display: none;
        margin-bottom: 0;
      }
      .shopping-bag-panel {
        &__title {
          color: $color-grey-dark;
          font-family: $bbtext-bold;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0.25px;
          line-height: 1.15;
          margin-bottom: 0;
          text-transform: uppercase;
          .edit-icon {
            background: url('/media/images/checkout/edit_icon.png') no-repeat $rdirection;
            background-size: 18px;
            cursor: pointer;
            float: $rdirection;
            height: 20px;
            width: 20px;
          }
        }
      }
      #links-panel {
        background: $color-grey-light;
        border: 0;
        margin-top: 0;
        padding: 22px 13px;
        .link {
          border-bottom: none;
          color: $color-pink;
          display: block;
          font-size: 12px;
          letter-spacing: 1.25px;
          line-height: 1.5;
          margin-bottom: 10px;
        }
        .links-panel__title {
          border-bottom: none;
          font-family: $bbtext-bold;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0.25px;
          line-height: 1.15;
          margin: 4px 0 10px;
          padding-bottom: 17px;
        }
      }
    }
  }
  &#payment {
    .column.right {
      &.checkout__sidebar {
        #shipping-panel {
          &.panel {
            display: block;
            overflow: visible;
          }
          .promocode-container,
          .transaction-details-content {
            display: none;
          }
          #review-address-info {
            .transaction-panel-details {
              .transaction-item {
                padding: 14px 13px 13px;
                .transaction-content dl {
                  margin: 0;
                }
                &.transaction-details-content {
                  display: block;
                }
                .transaction-content dl {
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
