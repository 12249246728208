.enhanced-delivery-page {
  .checkout__content {
    #delivery-options {
      margin-top: 22px;
      .delivery-title {
        color: $color-grey-dark;
        font-family: $bbtext-bold;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.25px;
        text-transform: uppercase;
      }
      .overlay-link-shipping-options {
        display: none;
      }
      .shipmethod-home-delivery {
        border: 1px solid $color-black;
        display: block;
        margin-bottom: 16px;
        padding: 17px 15px;
        .pointer-not-allowed {
          cursor: no-drop;
        }
        label {
          color: $color-grey-dark;
          font-size: 12px;
          letter-spacing: 0.25px;
          &::before {
            font-size: 20px;
          }
        }
      }
    }
  }
}
