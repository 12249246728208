/**
 * @file ru_pc.scss
 *
 * \brief Pull all parts of the RU-PC theme together into one file
 */
$color-vkontakte: #4680c2;

html,
button,
input,
select,
textarea {
  font-family: Arial, Verdana, sans-serif;
}

@import 'overrides/overrides';
@import '../../bb_base/scss/bobbibrown-base';
@import 'global/fontsize';
@import '../../emea_base/scss/emea_base_pc';
@import 'sections/_gwp_pc';

// SMACSS/sections/account
@import 'sections/pc/account/account';
@import '../../emea_base/scss/emea_search';
@import '../../emea_base/scss/sections/checkout/checkout-samples';

// SMACSS/Components/checkout
@import 'sections/pc/checkout/checkout';
@import 'sections/common/checkout/pickpoint';
@import 'sections/all';
@import 'sections/pc/all';

//see all offers
@import '../../emea_base/scss/sections/checkout/checkout-offers-promos';

// Live Engage
@import 'components/live_person';

//social login
@import 'components/social_login';
@import 'sections/social_login_v2';

//Password Field Tooltip
@import 'sections/account-reset-password';

//Enhanced Delivery
@import 'sections/enhanced-delivery';

// Book Appointment.
@import '../../emea_base/scss/sections/pc/_appointment-booking';
@import '../../emea_base/scss/sections/shared/_appointment-booking';

@import 'sections/loyalty_all';
