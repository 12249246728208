.enhanced-delivery-page {
  #delivery-options {
    h3 {
      margin-bottom: 14px;
    }
  }
  .ship-method-group-label {
    @include display-flex;
    .home_delivery_option,
    .click_and_collect_option {
      @include border-radius(2px);
      border: 1px solid $color-black;
      display: block;
      height: 94px;
      line-height: 6;
      margin-#{$rdirection}: 25px;
      opacity: 0.4;
      padding-top: 20px;
      position: relative;
      width: 267px;
      &::after {
        bottom: 83px;
        content: '';
        display: block;
        height: 30px;
        position: relative;
        pointer-events: none;
        #{$rdirection}: 100px;
      }
    }
    .home_delivery_option {
      padding-#{$ldirection}: 70px;
      &::after {
        background: url('/media/images/checkout/home_delivery_icon.png') no-repeat;
      }
    }
    .click_and_collect_option {
      padding-#{$ldirection}: 40px;
      &::after {
        background: url('/media/images/checkout/pickup-point-icon.png') no-repeat;
        background-size: 24px;
      }
    }
    input[type='radio'] {
      opacity: 0;
      &::after {
        bottom: 25px;
        content: '';
        cursor: pointer;
        display: block;
        height: 94px;
        position: relative;
        width: 230px;
      }
    }
    .add {
      opacity: 1;
    }
  }
  .ship-method {
    &--title {
      border: 0;
      font-size: 16px;
      letter-spacing: 0;
      padding: 0;
    }
    &-group {
      margin-bottom: 42px;
      &-item {
        border: 1px solid $color-black;
        border-radius: 2px;
        cursor: pointer;
        flex: 1 1 50%;
        height: 96px;
        opacity: 0.4;
        padding: 55px 0 20px;
        text-align: center;
        text-transform: uppercase;
        &:first-child {
          background: url('/media/images/checkout/home_delivery_icon.png') no-repeat center 22px;
          margin-#{$rdirection}: 11px;
        }
        &:last-child {
          background: url('/media/images/checkout/pickpoint-icon.png') no-repeat center 22px;
          background-size: 30px;
          margin-#{$ldirection}: 11px;
        }
        &.active {
          opacity: 1;
        }
      }
    }
  }
  .click-and-collect {
    .checkout-buttons {
      @include swap_direction(margin, 58px 0 0 auto);
      background: $color-black-2;
      display: block;
      font-size: 12px;
      padding: 20px;
      width: 348px;
    }
  }
  #shipping-page-info-panel {
    .shipping-title {
      .packstation-title {
        display: none;
      }
    }
    &.packstation-active {
      .shipping-title {
        .default-title {
          display: none;
        }
        .packstation-title {
          display: block;
          float: $ldirection;
          position: relative;
          .overlay-link-shipping-options {
            #{$ldirection}: 100%;
            background: url('/media/images/checkout/privacy-policy-info-icon.png') no-repeat center;
            border-radius: 50%;
            bottom: 0;
            content: '';
            cursor: pointer;
            height: 20px;
            margin-#{$ldirection}: 16px;
            opacity: 0.5;
            position: absolute;
            width: 20px;
          }
        }
      }
    }
  }
  .show-collection-point {
    padding: 16px 0;
    h4 {
      color: $color-grey-dark;
      font-family: $brandon-text-bold;
      font-size: 12px;
      line-height: 1.67;
      text-transform: uppercase;
      .local-collection-link {
        color: $color-grey-dark;
        float: $rdirection;
        line-height: 1.34;
      }
    }
  }
}
