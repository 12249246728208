.enhanced-delivery-page {
  .checkout-progress-main {
    display: block;
    float: none;
    margin: auto;
    max-width: 465px;
    overflow: hidden;
    padding: 18px 0 30px;
    li {
      &::before {
        @include border-radius(50%);
        background: $color-nobel;
        content: '';
        display: block;
        height: 12px;
        margin: 0 auto 4px;
        width: 12px;
      }
    }
  }
  .pg_wrapper {
    .checkout-progress-main {
      display: none;
    }
  }
  .wrapper {
    .checkout-progress-main {
      display: block;
    }
  }
  .checkout-progress-bar {
    @include display-flex;
    margin: auto;
    max-width: 370px;
    &__delivery-bar,
    &__payment-bar {
      #{$ldirection}: 76px;
      background: $color-nobel;
      bottom: 12px;
      content: '';
      display: block;
      height: 2px;
      position: relative;
      z-index: -1;
    }
    .confirmation-bar,
    #progress-review {
      display: none;
    }
    .progress-background {
      background-color: $color-black;
    }
    &__delivery {
      @include border-radius(50%);
      background-color: $color-gray-dark;
      height: 12px;
      width: 12px;
    }
    .checkout-progress {
      background-color: $color-gray-dark;
      height: 2px;
      margin-top: 5.5px;
      width: 155px;
    }
  }
  .checkout {
    ol.checkout-progress {
      @include display-flex;
      color: $color-nobel;
      float: $ldirection;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.1px;
      margin-top: 10px;
      position: relative;
      text-transform: uppercase;
      width: 100%;
      li {
        color: $color-gray-dark;
        list-style-type: none;
        margin: 0;
        text-align: center;
        width: 155px;
        .confirm-text {
          padding-top: 2px;
        }
      }
    }
  }
  &#payment {
    .checkout-progress-main {
      .checkout-progress-bar__delivery-bar {
        background-color: $color-black;
      }
      #progress-payment {
        background-color: $color-black;
        bottom: 1px;
        height: 16px;
        position: relative;
        width: 16px;
      }
    }
    ol.checkout-progress {
      li.shipping,
      li.review {
        color: $color-black;
        font-weight: 700;
        &::before {
          background-color: $color-black;
        }
      }
      li.review {
        &::before {
          height: 16px;
          margin-top: -4px;
          width: 16px;
        }
      }
      .confirm {
        font-weight: 700;
      }
    }
  }
  &#shipping {
    .checkout-progress-main {
      .shipping {
        &::before {
          background: $color-grey-dark;
          height: 16px;
          margin-top: -4px;
          width: 16px;
        }
      }
      .shipping-text {
        color: $color-grey-dark;
      }
    }
  }
}
