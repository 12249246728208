// Loyalty panels
.loyalty-panels {
  .loyalty-panels_header {
    border-top: 1px solid black;
    background-repeat: no-repeat;
    background-image: url('/media/export/images/loyalty/BB_SimplifiedCrest_Black_103.png');
    padding: 0 0 10px 120px;
    background-position: 0 30px;
    @include breakpoint($medium-up) {
      border-top: 3px solid black;
      background-image: url('/media/export/images/loyalty/BB_SimplifiedCrest_Black_130.png');
      padding: 0 0 10px 160px;
      background-position: 10px 15px;
    }
    h2 {
      @include h24;
      line-height: 25px;
      font-size: 21px;
      padding-top: 35px;
      margin-bottom: 0;
      @include breakpoint($medium-up) {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $color-black;
      }
      span {
        display: none;
        @include breakpoint($medium-up) {
          display: inline;
        }
      }
    }
  }
  .loyalty-panels__section {
    margin-top: 10px;
    &.first {
      margin-bottom: 20px;
    }
    .loyalty-panels_subtitle {
      width: 100%;
      @include breakpoint($small-down) {
        background-color: $color-gray-light-alt;
        font-family: $brandon-text-bold;
        text-transform: uppercase;
        padding: 10px 0;
        text-align: center;
      }
      span span {
        font-family: $brandon-text;
        text-transform: none;
      }
    }
  }
}

// Bottom button
#samples_loyalty_all_levels {
  .samples-buttons.bottom {
    display: block;
  }
}

// Product items
.product {
  &.deactivate .sample-select-button {
    background: $color-gray-light;
  }
}

// WP/MYOG samples page styles starts here
.wp-sample-page-pc {
  .samples-panel__title {
    font-size: 21px !important;
  }
  .wp_messages {
    font-size: 16px;
    text-align: center;
    padding: 2% 10%;
  }
  .no-offer-msg {
    margin-top: 6%;
  }
  .product {
    padding: 0;
  }
  .column-1 {
    width: 99% !important;
    padding-left: 36%;
  }
  .column-2 {
    width: 48% !important;
    padding-left: 10%;
  }
  .column-3 {
    width: 30% !important;
  }
  .wp-label-wrap {
    padding-top: 3%;
  }
  .sample-controls {
    margin: 20px 0;
  }
  .myog-btn-holder {
    float: right;
    padding-top: 10%;
  }
  .wp-footer-top {
    margin-bottom: 20px;
    text-align: left;
  }
  .wp-footer-bottom {
    margin-bottom: 20px;
    text-align: left;
  }
  .product-img img {
    width: auto !important;
  }
  .checkbox-disable {
    color: #9e9ea0;
    cursor: default;
  }
  .button-disable {
    background-color: #cccccc;
    cursor: default;
  }
  .clear-both {
    clear: both;
  }
  .float-none {
    float: none;
  }
}

.wp-sample-page-mb {
  .border-bottom {
    border-bottom: 1px solid #929292;
    margin-bottom: 8%;
  }
  .no-top_border {
    border-top: none;
  }
  .prod-desc {
    margin-top: 5%;
  }
  .wp-samples-contrl {
    margin: 10% 0;
  }
  .wp-lable-mb {
    margin: 3% 0;
  }
  .checkbox-disable {
    color: #9e9ea0;
    cursor: default;
  }
  .button-disable {
    background-color: #cccccc;
    cursor: default;
  }
  .no-offer-msg {
    margin-top: 20%;
    text-align: center;
  }
}

.viewcart {
  .wp_message {
    padding-top: 6%;
    font-size: 16px;
  }
  .wp-name {
    padding-bottom: 3%;
    line-height: 1;
  }
  .gift-item {
    font-size: 10px;
    cursor: pointer;
  }
  .loading-img {
    position: absolute;
    padding: 3% 0 0 6%;
  }
}

.checkout-panel--wp-samples.checkout-panel--samples {
  padding-top: 10px;
  @include breakpoint($landscape-up) {
    padding-top: 0;
  }
  .checkout-panel__header {
    text-align: center;
    @include breakpoint($landscape-up) {
      text-align: $ldirection;
    }
    h2 {
      display: block;
      font-size: 24px;
      line-height: 1;
      margin-bottom: 0;
      text-align: $ldirection;
    }
  }
  .samples-content {
    &__samples {
      padding: 10px 0;
    }
    &__continue-shopping {
      border-bottom: 1px solid $color-black-2;
      display: block;
      font-weight: bold;
      padding-bottom: 50px;
      text-align: center;
      text-decoration: underline;
      text-transform: uppercase;
    }
    &__free-header {
      font-size: 20px;
      font-weight: bold;
      padding: 20px 15px 0;
      text-transform: uppercase;
    }
    &__free-desc {
      font-size: 16px;
      padding: 0 15px;
      @include breakpoint($landscape-up) {
        padding: 0;
      }
    }
    &__sample-header,
    &__sample-subheader {
      font-family: $bbtext-bold;
      font-size: 16px;
      letter-spacing: 3px;
      margin: 20px 0;
      text-align: $ldirection;
      text-transform: uppercase;
      @include breakpoint($landscape-up) {
        margin: 15px 0;
      }
    }
    &__sample-header {
      border-bottom: 1px solid $color-black-2;
      padding-bottom: 10px;
    }
    &__sample-subheader {
      font-size: 15px;
      margin-bottom: 10px;
    }
    &__form {
      padding: 15px;
      @include breakpoint($landscape-up) {
        padding: 0;
      }
    }
    &__button--mobile,
    &__button-group--bottom {
      padding: 0;
      @include breakpoint($landscape-up) {
        padding: 15px 0;
      }
    }
    &__button--mobile {
      a {
        background: $color-black-2;
        color: $color-white;
        font-weight: bold;
      }
    }
    .product-img {
      display: initial;
      height: auto;
      width: auto;
      @include breakpoint($landscape-up) {
        width: 230px;
      }
    }
    &__button-group--top {
      margin-top: 50px;
      padding: 15px 0;
    }
    &__button {
      display: block;
      text-align: center;
      width: auto;
      @include breakpoint($landscape-up) {
        display: inline-block;
      }
      &--return,
      &--checkout {
        float: none;
        margin: 0 auto 10px;
        width: auto;
        @include breakpoint($landscape-up) {
          margin-bottom: 0;
        }
      }
      &--checkout {
        .button {
          background: $color-pink;
          width: 100%;
        }
      }
      @include breakpoint($medium-up) {
        &--return {
          float: $ldirection;
        }
        &--checkout {
          float: $rdirection;
          margin-#{$ldirection}: 0;
        }
      }
    }
    &__continue {
      background: $color-black-2;
      color: $color-white;
      float: $rdirection;
      margin-#{$rdirection}: 0;
    }
    .product {
      border: 0;
      float: $ldirection;
      margin-#{$rdirection}: 0.5%;
      margin-bottom: 30px;
      width: 100%;
      @include breakpoint($medium-up) {
        padding: 1%;
        width: 23%;
      }
      &.selected {
        @include breakpoint($landscape-up) {
          .sample-select-button {
            border: 0;
            min-width: 10px;
            &:hover {
              background: none;
              color: $color-black-2;
            }
          }
        }
      }
      .product-brief__title {
        border-top: 1px solid $color-black-2;
        font-family: $bbtext-bold;
        min-height: 50px;
        padding-top: 10px;
        text-transform: uppercase;
        @include breakpoint($landscape-up) {
          min-height: 70px;
        }
      }
      .skus {
        bottom: 0;
        margin-top: 0;
      }
      .sku {
        width: 100%;
        &:hover {
          color: $color-white;
        }
        &.disabled {
          background: none;
          opacity: 0.5;
        }
        a {
          display: inline-block;
          font-size: 14px;
          max-width: 100%;
          min-width: 116px;
          text-decoration: none;
          width: 100%;
          @include breakpoint($medium-up) {
            font-size: 15px;
            padding: 9px 19px 8px;
            width: 92%;
          }
        }
      }
    }
  }
  .button {
    font-size: 15px;
    line-height: 1.07;
    height: auto;
    padding: 9px 19px 8px;
  }
  #checkout_wp_samples {
    .product-list {
      .product {
        border-top: 1px solid $color-black-2;
        margin-#{$ldirection}: 0;
        margin-bottom: 30px;
        min-height: 380px;
        @include breakpoint($medium-up) {
          border-top: 0;
        }
        &:first-child {
          border-top: 0;
        }
        &-size {
          display: block;
        }
        &-brief__subtitle {
          display: none;
        }
      }
      .sku.disabled .sample-select-button {
        opacity: 0.5;
      }
      .skus {
        bottom: 0;
      }
    }
  }
}
// WP/MYOG samples page styles ends here
// GWP sample UI style
#viewcart-panel {
  .gwp-sample {
    .cart-item__remove-link {
      .remove_link.other {
        @include icon('close', 'before', false, false, 16px, $color-black, 'top');
        background: none;
        margin-top: 10px;
        overflow: hidden;
        padding: 0;
        width: 16px;
        &::before {
          font-weight: bold;
        }
      }
    }
  }
}
