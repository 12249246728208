.enhanced-delivery-page {
  input[type='radio'] {
    &:focus ~ label {
      outline: 0;
    }
  }
  .pg_wrapper {
    margin-bottom: 0;
    max-width: $max-width-medium;
    position: relative;
    width: auto;
    .wrapper-checkout {
      @include display-flex;
      .continue-checkout-buttons {
        opacity: 0.4;
      }
      .button-enable {
        opacity: 1;
      }
    }
    .column.left {
      @include box-shadow(-3px 0 6px -4px $color-gray inset);
      border-#{$rdirection}: 1px solid $color-gray;
      float: $ldirection;
      margin: 0;
      padding: 0 24px;
      width: calc(100% - 380px);
      .wrapper {
        margin: 0 auto;
        max-width: 562px;
        .account-profile__email {
          background: $color-grey-light;
          border-top: 1px solid $color-black;
          color: $color-black;
          font-size: 12px;
          height: 54px;
          letter-spacing: 0.5px;
          margin: 0 0 40px;
          padding: 16px;
        }
        #accept_terms {
          margin-top: 20px;
          input[type='checkbox'] {
            @include appearance(none);
            @include border-radius(2px);
            #{$ldirection}: 13%;
            border: 1px solid $color-grey-eclipse;
            float: $ldirection;
            height: 18px;
            margin-#{$ldirection}: 0;
            padding: 0;
            width: 18px;
            &:checked {
              background-color: $color-white;
              &::before {
                @include rotate(45deg);
                #{$ldirection}: 4px;
                border: solid $color-black;
                border-width: 0 2px 2px 0;
                content: '';
                display: block;
                height: 13px;
                position: relative;
                width: 6px;
              }
            }
            & ~ label {
              padding-#{$ldirection}: 30px;
              &::before {
                display: none;
              }
            }
          }
        }
        .shipping-page-info-panel {
          .error_messages {
            margin: 20px 0;
          }
        }
        .shipping-panel__title {
          background: none;
          border-bottom: none;
          border-top: none;
          font-size: 36px;
          font-weight: bold;
          letter-spacing: 0.25px;
          line-height: 0.9;
          margin: 0 0 12px;
          padding: 5px 0 0;
          text-align: center;
          text-transform: uppercase;
          width: 100%;
        }
        .shipping-title {
          border-bottom: none;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0.25px;
          line-height: 1.25;
          margin: 0 0 10px;
          padding: 0;
          width: calc(100% - 24px);
          &.cc-active {
            .default-title {
              display: none;
            }
            .packstation-title {
              display: block;
            }
          }
          .packstation-title {
            display: none;
            float: $ldirection;
            position: relative;
            .overlay-link-shipping-options {
              #{$ldirection}: 100%;
              background: url('/media/images/checkout/privacy-policy-info-icon.png') no-repeat center;
              border-radius: 50%;
              bottom: 0;
              content: '';
              cursor: pointer;
              height: 20px;
              margin-#{$ldirection}: 16px;
              opacity: 0.5;
              position: absolute;
              width: 20px;
            }
          }
        }
        #checkout_shipping {
          margin-top: 30px;
          padding: 0;
          .form_element {
            float: none;
            margin: 0;
            padding: 22px 0 0;
            position: relative;
            width: 100%;
            .address-form-name {
              #{$ldirection}: 0;
              color: $color-gray-dark;
              font-weight: normal;
              letter-spacing: 0.15px;
              padding-#{$ldirection}: 15px;
              pointer-events: none;
              position: absolute;
              top: 40px;
            }
            .field {
              &.error {
                border: 1px solid $color-red;
              }
            }
            input,
            .selectbox,
            &.static-country {
              @include swap_direction(padding, 17px 20px 13px 15px);
              appearance: none;
              background: $color-white;
              border-radius: 0;
              color: $color-black;
              font-size: 12px;
              height: 54px;
              letter-spacing: normal;
              margin: 0;
              width: 100%;
            }
            textarea {
              @include swap_direction(padding, 17px 20px 13px 15px);
              border-color: $color-black;
              font-size: 12px;
              height: 94px;
            }
            .countdown-container {
              display: none;
            }
            .selectbox {
              @include swap_direction(padding, 12px 20px 17px 15px);
              .selectBox-arrow {
                padding: 0 10px;
                width: 38px;
                &::before {
                  line-height: 4.4;
                }
              }
            }
            &.static-country {
              background: $color-light-brown;
              margin-top: 22px;
              &::after {
                display: none;
              }
            }
            .active {
              background: $color-white;
              font-size: 12px;
              height: 15px;
              margin-#{$ldirection}: 12px;
              padding-#{$ldirection}: 0;
              position: absolute;
              top: 12px;
            }
          }
          #delivery-options.sub_form {
            margin-top: 0;
            #delivery-instructions {
              .delivery-instruction__text {
                display: none;
              }
            }
          }
          .form-check {
            margin: 14px 0 23px;
            min-width: 100%;
            input[type='checkbox'] {
              #{$ldirection}: 0;
              appearance: none;
              border-radius: 2px;
              border: 1px solid $color-grey-eclipse;
              float: $ldirection;
              height: 18px;
              margin-#{$ldirection}: 0;
              padding: 0;
              width: 18px;
              &:checked {
                background-color: $color-white;
                &::before {
                  #{$ldirection}: 5px;
                  border: solid $color-black;
                  border-width: 0 2px 2px 0;
                  content: '';
                  display: block;
                  height: 13px;
                  position: relative;
                  transform: rotate(45deg);
                  width: 6px;
                }
              }
            }
            .form_element {
              padding-top: 5px;
            }
            label {
              padding-#{$ldirection}: 30px;
              &::before {
                display: none;
              }
            }
          }
          .shipping-address-form-container {
            margin-bottom: 25px;
            .country_container {
              background: $color-light-brown;
              border: 1px solid $color-black;
              color: $color-black;
              height: 54px;
              margin-top: 22px;
              max-width: 562px;
              padding: 18px 15px;
            }
          }
          .shipping-address-info {
            .address-info {
              display: none;
            }
            .address_controls {
              border-top: 1px solid $color-black-2;
              margin-bottom: 6px;
              .edit-shipping-address,
              .add-shipping-address {
                display: none;
              }
            }
            .loading {
              opacity: 1;
            }
            #address_form_container,
            .edit-address-container {
              border: 0;
            }
            #address_form_container,
            .shipping-address-form-container {
              border-bottom: 1px solid $color-black;
            }
            .edit-address-container,
            #address_form_container,
            .shipping-address-form-container {
              font-size: 12px;
              max-width: 660px;
              padding: 0 0 20px;
              .edit-shipping-address {
                display: block;
              }
              .form_element {
                margin-bottom: 0;
                input[type='text'],
                select {
                  color: $color-black;
                  margin-bottom: 0;
                  option {
                    color: $color-nobel;
                  }
                }
                .custom-icon {
                  height: 54px;
                }
              }
              .form_element.state_container {
                select {
                  @include swap_direction(padding, 17px 20px 13px 15px);
                  color: $color-black;
                  option {
                    color: $color-nobel;
                  }
                }
              }
              .country_container {
                background: $color-light-brown;
                border: 1px solid $color-black;
                color: $color-black;
                height: 54px;
                margin-bottom: 0;
                margin-top: 22px;
                padding: 18px 15px;
                max-width: 562px;
                em {
                  font-style: normal;
                }
              }
            }
            #address_form_container,
            #shipping-address-form-container {
              border-bottom: 0;
            }
          }
          #billing-address-info-panel {
            font-size: 12px;
            margin-bottom: 0;
            padding: 0;
            .billing-title {
              margin-top: 37px;
            }
            .billing-header {
              margin: 28px auto 10px;
            }
            #billing-info-panel,
            .billing-address {
              float: none;
              margin-bottom: 0;
              width: auto;
            }
            .address-info {
              border-bottom: 1px solid $color-black-2;
              border-top: 1px solid $color-black-2;
              color: $color-grey-dark;
              padding: 16px 0;
              text-transform: capitalize;
              max-width: 560px;
              address {
                font-style: normal;
                margin-#{$ldirection}: 14px;
              }
            }
            .bill-form-expand {
              .address-info {
                border-bottom: 0;
              }
            }
            .billing-info-panel {
              &__title {
                border: 0;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0.25px;
                line-height: 1.25;
                padding: 0;
              }
            }
            .address_controls {
              #{$rdirection}: 0;
              margin-top: 20px;
              position: absolute;
              top: 37px;
              li {
                position: relative;
                .edit-billing-address {
                  #{$rdirection}: 15px;
                  background: url('/media/images/checkout/edit_icon.png') no-repeat;
                  background-size: contain;
                  float: $rdirection;
                  height: 18px;
                  position: absolute;
                  top: 26px;
                  width: 18px;
                  &.cancel_link {
                    #{$rdirection}: 20px;
                    background: 0;
                    color: $color-black;
                    font-size: 14px;
                    text-indent: 0;
                    width: auto;
                  }
                }
              }
            }
            .edit-billing-address-form-container,
            #billing_address_form_container {
              border: none;
              border-bottom: 1px solid $color-black-2;
              padding: 0 0 15px;
              .shipping-info-panel__title {
                border: none;
                color: $color-grey-dark;
                font-family: $bbtext-bold;
                font-size: 16px;
                letter-spacing: 0.25px;
                padding: 0;
              }
              .form_element {
                &.country_container {
                  .label-content,
                  .address-form-name {
                    display: none;
                  }
                  select {
                    @include appearance(none);
                    @include border-radius(0);
                    @include swap_direction(padding, 17px 20px 13px 15px);
                    background: $color-white;
                    border-color: $color-black;
                    color: $color-black;
                    height: 54px;
                    letter-spacing: normal;
                    width: 100%;
                    option {
                      color: $color-nobel;
                    }
                  }
                  &:after {
                    border: 5px solid transparent;
                    border-top-color: $color-grey-shadow;
                    content: '';
                    pointer-events: none;
                    position: relative;
                    #{$rdirection}: 33px;
                    top: 10px;
                  }
                }
              }
            }
            #billing_address_form_container {
              border-bottom: 0;
              padding-bottom: 21px;
            }
          }
          .continue-save-btn {
            margin-top: 66px;
          }
          .shipping-save-btn {
            margin-top: 40px;
          }
          .continue-save-btn,
          .shipping-save-btn {
            @include box-shadow(none);
            margin-bottom: 50px;
            .shipping-continue-btn,
            .save-address {
              @include box-shadow(none);
              #{$ldirection}: 0;
              #{$rdirection}: auto;
              background: $color-white;
              bottom: 0;
              display: block;
              height: auto;
              padding: 0;
              position: static;
              text-align: $rdirection;
              width: 100%;
              z-index: 102;
              .form-submit {
                @include border-radius(0);
                background-color: $color-black-2;
                font-size: 12px;
                font-weight: bold;
                height: 48px;
                letter-spacing: 1.25px;
                line-height: 1.5;
                margin: 0;
                position: relative;
                width: 348px;
              }
            }
          }
          .address-form__content {
            .delivery_instructions {
              textarea {
                @include swap_direction(padding, 17px 20px 13px 15px);
                border: 1px solid $color-black-2;
                font-size: 12px;
                resize: none;
              }
              .address-form-name {
                bottom: 80px;
              }
            }
          }
          .checkout input[type='submit'].btn {
            background-color: $color-black-2;
          }
          .checkout-buttons-container {
            .checkout-buttons {
              background-color: $color-black;
              color: $color-white;
              height: 48px;
              width: 348px;
            }
          }
          #shipping-address-info-panel {
            &.ship-method-home-active {
              .delivery-address-container {
                &.delivery_home {
                  display: block;
                }
                &.delivery_pack {
                  display: none;
                }
              }
            }
            &.ship-method-pack-active {
              .delivery-address-container {
                &.delivery_pack {
                  display: block;
                }
                &.delivery_home {
                  display: none;
                }
              }
            }
          }
          #shipping-address-info-panel #shipping-info-panel {
            margin-bottom: 0;
            &.new-form-expand {
              .address_controls {
                #choose-address {
                  border-bottom: 0;
                }
              }
            }
            .address_controls #choose-address {
              border-bottom: 1px solid $color-black;
              margin-bottom: 0;
              padding-bottom: 17px;
              input[type='radio'] {
                #{$ldirection}: 20px;
                opacity: 0;
              }
              &.shipping-edit-mode {
                opacity: 0.7;
              }
              .delivery-address-container {
                border-bottom: 1px solid $color-black;
                margin-top: 20px;
                label {
                  @include swap-direction(margin, 0 0 0 14px);
                  &::before {
                    font-size: 20px;
                  }
                  .change_link {
                    background: url('/media/images/checkout/edit_icon.png') no-repeat;
                    background-size: contain;
                    float: $rdirection;
                    height: 18px;
                    margin-#{$rdirection}: 17px;
                    position: relative;
                    text-indent: -9999px;
                    width: 18px;
                    z-index: 1;
                    &.cancel_link {
                      #{$rdirection}: 20px;
                      background: 0;
                      margin-#{$rdirection}: 0;
                      text-decoration: underline;
                      text-indent: 0;
                      width: auto;
                    }
                  }
                }
                .delivery_address {
                  #{$ldirection}: 50px;
                  bottom: 20px;
                  color: $color-grey-dark;
                  font-size: 12px;
                  position: relative;
                  &.nonreg_user_address {
                    .co_default {
                      display: none;
                    }
                  }
                  .co_default {
                    font-weight: bold;
                    text-transform: uppercase;
                  }
                }
                .edit-shipping-address {
                  label {
                    margin: 0;
                  }
                }
              }
              .add-new-address {
                max-width: 522px;
                padding-top: 15px;
                position: relative;
                width: 100%;
                .add-address {
                  @include swap-direction(margin, 0 0 0 14px);
                  color: $color-grey-dark;
                  display: block;
                  font-size: 20px;
                  font-weight: bold;
                  letter-spacing: 0.25px;
                  text-decoration: none;
                  max-width: 560px;
                }
                .add-address-label {
                  #{$ldirection}: 50px;
                  bottom: 0;
                  font-size: 12px;
                  font-weight: bold;
                  letter-spacing: 0.25px;
                  position: absolute;
                  text-transform: uppercase;
                }
                .add-radio {
                  @include icon('radio');
                }
                .add-radio-checked {
                  @include icon('radio_checked');
                }
                .delivery-address-container {
                  border-bottom: 1px solid $color-black;
                  margin-top: 20px;
                  padding-#{$ldirection}: 17px;
                  label {
                    &::before {
                      font-size: 14px;
                    }
                    .change_link {
                      background: url('/media/images/checkout/edit_icon.png') no-repeat;
                      background-size: contain;
                      float: $rdirection;
                      height: 18px;
                      margin-#{$rdirection}: 17px;
                      position: relative;
                      text-indent: -9999px;
                      width: 18px;
                      z-index: 1;
                      &.cancel_link {
                        #{$rdirection}: 20px;
                        background: 0;
                        text-decoration: underline;
                        text-indent: 0;
                        width: auto;
                      }
                    }
                  }
                  .delivery_address {
                    #{$ldirection}: 30px;
                    bottom: 20px;
                    font-size: 12px;
                    position: relative;
                    .co_default {
                      font-weight: bold;
                      text-transform: uppercase;
                    }
                  }
                }
                .default_ship_container {
                  padding: 0;
                  input[type='checkbox'] {
                    @include appearance(none);
                    @include border-radius(2px);
                    #{$ldirection}: 0;
                    border: 2px solid $color-grey-eclipse;
                    float: $ldirection;
                    height: 18px;
                    margin-#{$ldirection}: 15px;
                    padding: 0;
                    width: 18px;
                    &:checked {
                      background-color: $color-white;
                      &::before {
                        @include rotate(45deg);
                        #{$ldirection}: 4px;
                        border: solid $color-black;
                        border-width: 0 2px 2px 0;
                        content: '';
                        display: block;
                        height: 13px;
                        position: relative;
                        top: -1px;
                        width: 6px;
                      }
                    }
                  }
                  label {
                    &::before {
                      display: none;
                    }
                  }
                  .label-content {
                    margin-top: 14px;
                  }
                }
              }
            }
          }
          .terms_conditions_container {
            display: none;
          }
          .info-content {
            margin: 31px 0 45px;
            position: relative;
            .info-link-message {
              #{$ldirection}: -1px;
              background: url('/media/images/checkout/privacy-policy-info-icon.png') no-repeat center;
              background-size: 100%;
              cursor: pointer;
              display: block;
              height: 20px;
              position: absolute;
              text-align: $ldirection;
              text-indent: -9999px;
              width: 20px;
            }
            .info-link {
              @include swap_direction(padding, 2px 0 0 30px);
              color: $color-black;
              font-size: 12px;
              font-weight: bold;
              letter-spacing: 1.25px;
              line-height: 1.1;
              margin: 0;
              position: relative;
              text-decoration: underline;
              text-transform: uppercase;
              top: 2px;
            }
          }
          #corporate-invoice-options {
            font-size: 12px;
            .corporate_options {
              .corporate-vat-lookup-instructions {
                margin: 6px 0;
              }
              .form-item {
                input {
                  font-size: 12px;
                  margin: 15px 0 0;
                  &[type='text'] {
                    &:read-only {
                      background: $color-light-brown;
                      color: $color-black;
                      pointer-events: none;
                    }
                  }
                }
                .corporate-vat-lookup {
                  background-color: $color-black;
                  color: $color-white;
                  display: block;
                  font-size: 13px;
                  font-weight: bold;
                  height: 50px;
                  line-height: 1.42;
                  margin-#{$ldirection}: auto;
                  margin-top: 15px;
                }
              }
              .corporate-invoice-form-element {
                padding-top: 22px;
              }
            }
          }
          #giftwrap-info-panel {
            .gift-options {
              .giftwrap-panel__title {
                border-top: 1px solid $color-black;
                padding: 10px;
              }
            }
          }
        }
      }
    }
  }
  &#shipping {
    .column.left {
      .wrapper {
        .shipping-title {
          .default-title {
            color: $color-grey-dark;
            font-family: $bbtext-bold;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
