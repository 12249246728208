$base-font-ru: 13px !important;

.about-bobbi {
  &__footer {
    &__cta {
      font-size: $base-font-ru;
    }
  }
  &__causes {
    &__partner {
      &__toggle {
        font-size: $base-font-ru;
      }
    }
  }
  &__story {
    &-bobbi__prologue {
      font-size: $base-font-ru;
    }
    &-chapters {
      p {
        font-size: $base-font-ru;
      }
    }
  }
}

.mpp {
  .product-thumb {
    &__headline {
      h3 {
        font-size: $base-font-ru;
      }
    }
  }
  .product__title {
    h3 {
      font-size: $base-font-ru;
    }
  }
}

body {
  font-size: $base-font-ru;
}

#product-results {
  .product-thumb {
    &__headline {
      h3 {
        font-size: $base-font-ru;
      }
    }
  }
}

.spp {
  .product-thumb {
    &__headline {
      h3 {
        font-size: $base-font-ru;
      }
    }
  }
}

.mobile-menu__dd-2 {
  li > a {
    font-size: $base-font-ru;
  }
}

.store-locator {
  .store-locator-section--list_item-section {
    font-size: $base-font-ru;
  }
}

.store-locator--desktop {
  .store-locator__locate,
  .store-locator__dutyfree {
    span.text {
      font-size: $base-font-ru;
    }
  }
  .store-locator__hero {
    font-size: $base-font-ru;
  }
  .infoBox {
    .store-locator__tooltip {
      font-size: $base-font-ru;
      &-name {
        font-size: $base-font-ru;
      }
    }
  }
  .store-locator-section--list_item .store-locator__tooltip {
    font-size: $base-font-ru;
  }
  .store-locator-section--list {
    .store-locator__event {
      font-size: $base-font-ru;
      .store-locator__event-list__item {
        h5 {
          font-size: $base-font-ru;
        }
      }
    }
  }
}

@mixin h5 {
  font-size: $base-font-ru;
}

@mixin h8 {
  @include breakpoint($medium-up) {
    font-size: $base-font-ru;
  }
}

@mixin h9 {
  font-size: $base-font-ru;
}

@mixin h13 {
  @include breakpoint($medium-up) {
    font-size: $base-font-ru;
  }
}

@mixin h14 {
  font-size: $base-font-ru;
}

@mixin h16 {
  font-size: $base-font-ru;
  @include breakpoint($medium-up) {
    font-size: $base-font-ru;
  }
}

@mixin h17 {
  font-size: $base-font-ru;
}

@mixin h24 {
  font-size: $base-font-ru;
}

.selectbox,
a.selectBox,
.selectBox-options a {
  font-size: $base-font-ru;
}

.bobbi-pro {
  &__section-content {
    font-size: $base-font-ru;
  }
  &__apply {
    .bobbi-pro__section-link {
      font-size: $base-font-ru;
    }
  }
  &__artist {
    &-bio__content-section {
      font-size: $base-font-ru;
    }
  }
}
%button {
  font-size: $base-font-ru;
}

@mixin link($color-active, $color-hover, $touch: true) {
  font-size: $base-font-ru;
}

.l6 {
  font-size: $base-font-ru;
}

.callout-alt {
  &__description {
    font-size: $base-font-ru;
  }
}

.chat-landing-page {
  &__text {
    font-size: $base-font-ru;
  }
  &__offers-item {
    font-size: $base-font-ru;
  }
}

.duty-free {
  &__hero {
    font-size: $base-font-ru;
  }
  &__store-locator {
    .text {
      font-size: $base-font-ru;
    }
  }
}

.homepage-slide {
  &__description {
    font-size: $base-font-ru;
  }
}

.live-chat-landing {
  .block_container {
    h4.block_container__title {
      font-size: $base-font-ru;
    }
  }
  .text-block {
    &_body {
      .times {
        font-size: $base-font-ru;
      }
    }
    &_link a {
      font-size: $base-font-ru;
    }
  }
}

.mpp-custom-header {
  .page-header {
    .lip-color {
      &__bobbiline-text {
        @include breakpoint($small-down) {
          @include breakpoint(orientation portrait) {
            font-size: $base-font-ru;
          }
        }
      }
      &__bobbiline-text {
        @include breakpoint($small-down) {
          @include breakpoint(orientation portrait) {
            font-size: $base-font-ru;
          }
        }
      }
    }
  }
}

.account-overlay,
.email-overlay {
  .account-overlay__next {
    font-size: $base-font-ru;
  }
  .account-inline,
  .overlay__container--email {
    font-size: $base-font-ru;
  }
}

.product-promo {
  &__headline {
    @include breakpoint($medium-up) {
      font-size: $base-font-ru;
    }
  }
  &__title {
    span {
      @include breakpoint($medium-up) {
        font-size: $base-font-ru;
      }
    }
  }
  &__copy {
    @include breakpoint($medium-up) {
      font-size: $base-font-ru;
    }
  }
  &__link {
    span {
      @include breakpoint($medium-up) {
        font-size: $base-font-ru;
      }
    }
  }
}

.product-thumb {
  .product-thumb__cta {
    @include breakpoint($medium-up) {
      font-size: $base-font-ru;
    }
  }
  .product__favorite {
    font-size: $base-font-ru;
  }
}

.product-collection-tabs {
  .tabs-list {
    li {
      font-size: $base-font-ru;
    }
  }
}

.product_collection_hero-v3 {
  .menu {
    li {
      font-size: $base-font-ru;
    }
  }
}

.password-request-page,
.password-sent-page,
.password-reset-page {
  @include breakpoint($medium-up) {
    &__subheader {
      @include breakpoint($medium-up) {
        font-size: $base-font-ru;
      }
    }
    .account-utilities {
      &__header {
        @include breakpoint($medium-up) {
          font-size: $base-font-ru;
        }
      }
    }
  }
}

.device-mobile .store-locator-mobile-v1 {
  .location_info h1 {
    font-size: $base-font-ru;
  }
  .doors_body {
    font-size: $base-font-ru;
  }
}

.store-locator-mobile-popup-v1 {
  .event-popup-content {
    font-size: $base-font-ru;
  }
}

#emailTranscript,
#closeChat {
  font-size: $base-font-ru;
}

.beauty-consultations-large-msg .msg-content p {
  font-size: $base-font-ru;
}

.detail_hero_instant_confidence {
  .product__button,
  .text-block_link a {
    font-size: $base-font-ru;
  }
}

.skin_cushion {
  .skin_cushion_chart {
    .text-block {
      .note {
        font-size: $base-font-ru;
      }
    }
    .seamless-application {
      .note {
        font-size: $base-font-ru;
      }
    }
  }
}

.search-form {
  .search-suggestions__link {
    font-size: $base-font-ru;
  }
}

.product_guide_dropdown {
  .guide_dropdown {
    &__callout {
      font-size: $base-font-ru;
    }
    &__cta {
      font-size: $base-font-ru;
    }
  }
}

.corrector-concealer {
  .block_container {
    &__title {
      font-size: $base-font-ru;
    }
  }
  .cc {
    &_product {
      &__boxed {
        font-sized: $base-font-ru;
      }
    }
    &_section_head {
      font-size: $base-font-ru;
    }
  }
}

.mpp.gift_guide {
  .product-filter {
    &__filter {
      font-size: $base-font-ru;
    }
  }
}

.holiday {
  &.services {
    .product_collection_hero {
      &__content {
        &-item {
          .text-block {
            &_link a {
              font-size: $base-font-ru;
            }
            &_live-chat-times .text-block_body {
              font-size: $base-font-ru;
            }
          }
        }
      }
    }
  }
}

.bwya {
  &__page {
    .product__social {
      font-size: $base-font-ru;
    }
  }
}

.hp-citia {
  &__title {
    &--small {
      font-size: $base-font-ru;
    }
  }
}

.hp-mul-product {
  &_callout {
    font-size: $base-font-ru;
  }
  a {
    font-size: $base-font-ru;
  }
}

.hp-multitaskers {
  h4 {
    font-size: $base-font-ru;
  }
  .product-image_link a {
    font-size: $base-font-ru;
  }
}

.art_sticks {
  .product_collection_hero {
    &__content-item {
      &:nth-child(3) {
        .text-block {
          .text-block_body p {
            font-size: $base-font-ru;
          }
        }
      }
    }
  }
}

.brush-finder {
  .product-filter {
    &__label {
      font-size: $base-font-ru;
    }
  }
}

.eye-opener {
  .product_collection {
    &_hero {
      &__content {
        .block_container__title {
          font-size: $base-font-ru;
        }
      }
    }
  }
  .video-block {
    &__headline {
      font-size: $base-font-ru;
    }
  }
}

.foundation-finder {
  &__mpp-cta,
  &__shop-all-link {
    font-size: $base-font-ru;
  }
  &__tips {
    &__header {
      font-size: $base-font-ru;
    }
  }
}

.foundation_stick {
  .product_collection_hero {
    &__content-item {
      .text-block {
        .text-block_body p {
          font-size: $base-font-ru;
        }
      }
    }
  }
}

.detail_hero_issf-spf-40 {
  .detail_hero {
    &-description_1 {
      &_shades {
        font-size: $base-font-ru;
      }
    }
  }
}

.luxe-lip-curalate {
  .product_collection_hero {
    @include breakpoint($small-down) {
      &__subtitle {
        font-size: $base-font-ru;
      }
    }
  }
}

.lw-collection {
  .product-collection-product {
    h2,
    h3 {
      font-size: $base-font-ru;
    }
  }
}

.malibu_nudes {
  h3 {
    font-size: $base-font-ru;
  }
  .product-palette-atb {
    p {
      font-size: $base-font-ru;
    }
  }
}

.nude_finish {
  h3 {
    font-size: $base-font-ru;
  }
  .product-atb {
    p {
      font-size: $base-font-ru;
    }
  }
}

.product--full {
  .product {
    &__header {
      @include breakpoint($small-down) {
        .product__title {
          font-size: $base-font-ru;
        }
      }
    }
    &__tip {
      font-size: $base-font-ru;
    }
  }
}

.product-guide-item-bundle {
  &__meta-title {
    @include breakpoint($medium-up) {
      font-size: $base-font-ru;
    }
  }
  &__meta-details {
    @include breakpoint($medium-up) {
      font-size: $base-font-ru;
    }
  }
}

.product-guide-item {
  &__meta-title {
    @include breakpoint($medium-up) {
      font-size: $base-font-ru;
    }
  }
  &__meta-details {
    @include breakpoint($medium-up) {
      font-size: $base-font-ru;
    }
  }
}

.product-le-shade {
  &__meta-title {
    @include breakpoint($medium-up) {
      font-size: $base-font-ru;
    }
  }
  &__meta-details {
    @include breakpoint($medium-up) {
      font-size: $base-font-ru;
    }
  }
}

.product-palette {
  &__link-launch {
    @include breakpoint($small-down) {
      font-size: $base-font-ru;
    }
  }
}

.secret-weapons {
  .text-block {
    &_carousel {
      h4 {
        font-size: $base-font-ru;
      }
    }
  }
}

.skincare-finder {
  .product-filter {
    &__label {
      font-size: $base-font-ru;
    }
  }
}

.js-sku-menu-mobile-text {
  font-size: $base-font-ru;
}

.everything-brows {
  .text-block {
    font-size: $base-font-ru;
  }
}

.how_to-lesson__step {
  &-cta .button {
    @include breakpoint($tablet) {
      font-size: $base-font-ru;
    }
  }
  &-product-grid__wrapper {
    .icon-close {
      font-size: $base-font-ru;
    }
  }
}

@mixin offers-sub-text {
  font-size: $base-font-ru;
}

.offers-hero {
  &__dropdown-product {
    &-header {
      font-size: $base-font-ru;
    }
  }
}

.offer-specific__container {
  .offer {
    &-hero {
      &__link {
        font-size: $base-font-ru;
      }
    }
  }
}

.store-locator.store-locator--mobile {
  .store-locator__tooltip {
    &-name {
      font-size: $base-font-ru;
    }
  }
  .doors_results {
    .store-locator__tooltip {
      .store-locator__tooltip-name {
        font-size: $base-font-ru;
      }
    }
  }
}

.appt-book {
  &-link {
    font-size: $base-font-ru;
  }
  .appointment-select {
    .appointments-container {
      .service {
        .service-head {
          @include breakpoint($small-down) {
            font-size: $base-font-ru;
          }
        }
      }
      #change-services-link,
      #read-all-artist-link,
      #appt-book-sign-in-link,
      .start-over {
        font-size: $base-font-ru;
      }
      .appointment-select {
        .calendar-head {
          @include breakpoint($small-down) {
            font-size: $base-font-ru;
          }
        }
        @include breakpoint($small-down) {
          .date-picker {
            .pikaday-container {
              .pika-single {
                .pika-lendar {
                  table.pika-table {
                    td {
                      .pika-button {
                        font-size: $base-font-ru;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .sign-in-container {
        .sign-in-form-container {
          input {
            &.login__submit {
              font-size: $base-font-ru;
            }
          }
        }
      }
    }
  }
  .confirmation {
    .confirmation-container {
      .location {
        a {
          font-size: $base-font-ru;
        }
      }
    }
  }
  .my-appointments {
    .appointments-container {
      &.past {
        .past-appt-head {
          a.view-link {
            font-size: $base-font-ru;
          }
        }
      }
    }
    .appointment-container {
      .appointment-details {
        @include breakpoint($small-down) {
          font-size: $base-font-ru;
        }
        .location {
          a {
            font-size: $base-font-ru;
          }
        }
      }
    }
  }
  &.appt-book-datepicker-overlay {
    .overlay-content-container {
      .pika-label {
        @include breakpoint($small-down) {
          font-size: $base-font-ru;
        }
      }
    }
  }
  &.my-appointments-overlay .overlay-content-container {
    .canceled {
      h4 {
        @include breakpoint($small-down) {
          font-size: $base-font-ru;
        }
      }
    }
  }
}

.cart_overlay {
  .cart-confirm-wrapper {
    .products-heading {
      span {
        font-size: $base-font-ru;
      }
    }
    .cart-products {
      .view_bag {
        a {
          font-size: $base-font-ru;
        }
      }
    }
    .add_view {
      h2 {
        font-size: $base-font-ru;
      }
    }
  }
}

.product-fave-confirm {
  &__copy,
  &__copy p {
    font-size: $base-font-ru;
  }
}

textarea {
  font-size: $base-font-ru;
}

input[type='submit'],
input[type='button'],
button {
  font-size: $base-font-ru;
}

.touch {
  select {
    font-size: $base-font-ru;
  }
}

.homepage-hero {
  &__description {
    font-size: $base-font-ru;
  }
}

.makeup-lessons-item {
  .makeup-lessons-item__lesson-button {
    @include breakpoint($tablet) {
      font-size: $base-font-ru;
    }
  }
}

.makeup-lesson-page {
  &__slider {
    .makeup-lesson-page__slider-slide {
      &-type {
        @include breakpoint($tablet) {
          font-size: $base-font-ru;
        }
      }
    }
  }
}

.makeup-lesson__step {
  &-cta .button {
    @include breakpoint($tablet) {
      font-size: $base-font-ru;
    }
  }
  &-product-grid__wrapper {
    .icon-close {
      font-size: $base-font-ru;
    }
  }
}

.makeup-lesson__event {
  &-product-grid__wrapper {
    .icon-close {
      font-size: $base-font-ru;
    }
  }
}

.egiftcard-cashstar {
  &__desc {
    font-size: $base-font-ru;
  }
  &__disclaimer {
    @include breakpoint($medium-up) {
      font-size: $base-font-ru;
    }
  }
}

.giftcard-balance__container.giftcard-balance-cashstar {
  & .giftcard-balance {
    &__header {
      font-size: $base-font-ru;
    }
  }
}

.site-footer {
  @include breakpoint($medium-up) {
    font-size: $base-font-ru;
  }
  &-toggle-device {
    a.toggle-mobile {
      font-size: $base-font-ru;
    }
  }
}

.sticky-offer,
.sticky-chat {
  &__copy {
    font-size: $base-font-ru;
  }
  &__headline {
    font-size: $base-font-ru;
  }
}

.site-header {
  &__main {
    .site-utils__item--search {
      .site-utils__icon {
        font-size: $base-font-ru;
      }
    }
  }
}

.site-nav {
  .menu {
    &__link {
      &--lvl-3 {
        font-size: $base-font-ru;
      }
    }
    .menu {
      &__link--lvl-1 {
        font-size: $base-font-ru;
      }
    }
  }
}

.site-utils {
  &__logo-icon {
    @media only screen and (max-device-width: 1279px) and (max-device-width: 1281px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
      font-size: $base-font-ru;
    }
  }
  &__icon {
    &--search {
      font-size: $base-font-ru;
    }
  }
}

.payment-form,
.payment-address {
  &__header {
    font-size: $base-font-ru;
  }
}

.artist-picks-page,
.chat-history-page {
  &__subheader {
    // TODO: Consolidation candidate (see address book)
    @include breakpoint($medium-up) {
      font-size: $base-font-ru;
    }
  }
  .transcript {
    .transcript__chat {
      &--lines {
        .transcript-lines {
          a.view-more {
            font-size: $base-font-ru;
          }
        }
      }
      &--skus {
        .recommended-skus {
          &__header {
            font-size: $base-font-ru;
          }
        }
      }
    }
  }
}

.favorites {
  &-page {
    &__subheader {
      @include breakpoint($medium-up) {
        font-size: $base-font-ru;
      }
    }
  }
}

.cart-item {
  &__desc {
    &-info {
      .shade {
        font-size: $base-font-ru;
      }
    }
  }
  &__size {
    font-size: $base-font-ru;
  }
}

.replenishment__description {
  &--text {
    @include breakpoint($medium-up) {
      font-size: $base-font-ru;
    }
  }
}
#{$sample-page} {
  .remaining {
    &__note {
      font-size: $base-font-ru;
    }
  }
}

#checkout_wp_samples {
  @include breakpoint($medium-up) {
    .pick-samples-btn a {
      font-size: $base-font-ru;
    }
  }
}

.checkout__header-item-count {
  .checkout__sidebar & {
    font-size: $base-font-ru;
  }
}

.cart-header {
  font-size: $base-font-ru;
}

@mixin giftcard-tab-text {
  font-size: $base-font-ru;
}

.giftcard {
  &__price {
    &-option {
      font-size: $base-font-ru;
    }
  }
  &__guides {
    &-header {
      font-size: $base-font-ru;
    }
  }
}

.selectBox-dropdown {
  font-size: $base-font-ru;
}

.selectBox-options {
  font-size: $base-font-ru;
}

.tooltipster-replenish {
  .tooltipster-content {
    h2 {
      font-size: $base-font-ru;
    }
  }
}

.spp {
  @include breakpoint($tablet) {
    .product__sku-categories-nav-item {
      font-size: $base-font-ru;
    }
  }
}

.menu__item menu__item--lvl-2 {
  .menu__link menu__link--lvl-2 {
    font-size: $base-font-ru;
  }
}

.homepage-hero__slide__inner {
  .homepage-hero__link.link {
    font-size: $base-font-ru;
  }
}

.site-nav {
  .menu__link--lvl-2 {
    font-size: $base-font-ru;
  }
}

.link {
  font-size: $base-font-ru;
}

.site-footer-contact__link,
.site-footer-social-links__header {
  font-size: $base-font-ru;
}

.site-footer__column:nth-child(2) {
  .menu__item {
    font-size: $base-font-ru;
  }
}

.site-footer__main {
  .site-email-signup__header {
    font-size: $base-font-ru;
  }
}

.section-head {
  h3.section-header__header {
    font-size: $base-font-ru;
  }
}

input[type='email'],
input[type='text'],
input[type='password'] {
  font-size: $base-font-ru;
}

h4.checkout__subtitle {
  font-size: $base-font-ru;
}

h2.checkout__panel-title {
  font-size: $base-font-ru;
}

#form--offer_code--field--OFFER_CODE {
  font-size: $base-font-ru;
}

.__web-inspector-hide-shortcut__ {
  font-size: $base-font-ru;
}

.page-header__subtitle {
  .link {
    font-size: $base-font-ru;
  }
}

.sign-in-page__login,
h2.section-head__header {
  font-size: $base-font-ru;
}

.new-account__fieldset {
  font-size: $base-font-ru;
}

.return-user__fieldset {
  font-size: $base-font-ru;
}

.product-thumb--price {
  font-size: $base-font-ru;
}

h3.account-utilities__header {
  font-size: $base-font-ru;
}

.recommended-products-panel__content,
.favorites__recommended-product {
  .product_name a {
    font-size: $base-font-ru;
  }
}

.spp {
  .product--full {
    .product__price {
      font-size: $base-font-ru;
    }
  }
}

h8 {
  font-size: $base-font-ru;
}

.responsive-tabs {
  .resp-tab-item {
    font-size: $base-font-ru;
  }
}

.section-header {
  font-size: $base-font-ru;
  .section-header__header {
    font-size: $base-font-ru;
  }
}

.BVRRRootElement {
  font-size: $base-font-ru;
}

h3.profile-info__header,
h3.optional-info__header,
h3.newsletter-info__header,
h3.address-book__header {
  font-size: $base-font-ru;
}

.page-header__title.page-header__title--small {
  font-size: $base-font-ru;
}
