/* signin & checkout page */
.social_login_v2 {
  &__social-login-container {
    text-align: center;
  }
  &__delimeter {
    clear: both;
    &-horizontal {
      position: relative;
      text-align: center;
      margin-bottom: 21px;
      margin-top: 21px;
    }
  }
  &__delimeter-horizontal:before {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    top: 9px;
    #{$ldirection}: 0;
    margin-bottom: 0;
    background: $color-black;
  }
  &__delimeter-label {
    @include swap_direction(padding, 0 7px 0 9px);
    font-size: 17px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    background: $color-white;
    position: relative;
  }
}

.social_login {
  .social-login-container {
    &__social-network {
      width: 55px;
      height: 55px;
      margin: 0 25px;
      display: inline-block;
      position: relative;
      @include breakpoint($medium-up) {
        width: 265px;
        height: 40px;
      }
      @include breakpoint($medium-only) {
        margin-top: 10px;
      }
      &-button {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        letter-spacing: 0.25px;
        &_vkontakte,
        &_vkontakte:hover {
          background: $color-vkontakte url(/media/images/social_login/vk_icon.svg) 10px no-repeat !important;
        }
        &_facebook,
        &_facebook:hover {
          background: $color-facebook url(/media/images/social_login/fb_icon.svg) 0 center no-repeat !important;
          background-size: 40px 40px !important;
        }
        &-label {
          display: none;
          @include breakpoint($medium-up) {
            font-size: 12px;
            padding-#{$ldirection}: 20px;
            display: block;
          }
        }
      }
      &-blocker {
        height: 100%;
        position: absolute;
        width: 100%;
        &_hidden {
          display: none;
        }
      }
    }
  }
}

.sign-in-component_ru__legal-container {
  width: 95%;
  margin: 20px auto;
  @include breakpoint($medium-up) {
    width: 500px;
  }
  .legal-container__acceptance-block {
    padding-bottom: 10px;
    &_error {
      color: $color-pink;
    }
  }
}
/* Acoount profile-page */
.profile-page__content {
  .social-info__connect {
    margin-bottom: 20px;
  }
  .social-info__vk {
    form {
      display: inline-block;
      .button {
        line-height: 20px;
      }
    }
    .button {
      height: 20px;
    }
    .vkontakte-login,
    .vkontakte-login:hover {
      background: $color-vkontakte url(/media/images/social_login/vk_icon_21x21.png) 4px 3px no-repeat !important;
      background-size: 15px !important;
    }
    .vkontakte-login {
      padding-#{$ldirection}: 25px;
      font-weight: 700;
      text-transform: none;
      letter-spacing: 0;
      font-size: 11px !important;
      border-radius: 4px;
      line-height: 2px;
    }
  }
}
/* checkout order conformation page */
.confirm {
  .confirm-panel {
    .social-login-container__social-network {
      margin: 0 20px;
      @include breakpoint($medium-only) {
        margin-top: 10px;
      }
    }
  }
}
/* Gnav signin */
.gnav {
  .social_login_v2__social-login-container {
    .social-login-container__social-network {
      width: 55px;
      height: 55px;
      &-button {
        margin-#{$ldirection}: 0;
        &-label {
          display: none;
        }
        width: 100%;
        height: 100%;
      }
      &-blocker {
        height: 100%;
      }
    }
  }
}

.sign-in-component_ru__legal-container-gnav {
  width: 100%;
  padding-top: 20px;
  padding-#{$ldirection}: 10px;
  .legal-container__acceptance-block_error,
  a {
    color: $color-pink;
  }
}
