$font_updates: true;
$disable-bbv2-in-checkout: true;
$brandon-text: Arial, Verdana, sans-serif;
$brandon-text-regular: $brandon-text;
$brandon-text-light: Arial, Verdana, sans-serif;
$brandon-text-medium: Arial, Verdana, sans-serif;
$brandon-text-bold: Arial, Verdana, sans-serif;
$base-font-family: $brandon-text;
$header-font-family: $brandon-text-bold;

// Enable Appointment Booking Reskin 2019.
$ab_skin_v2: true;
