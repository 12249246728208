/* Search */
.section-esearch {
  #search-wrapper {
    margin-top: 40px;
    #col-1,
    #summary,
    .results-summary,
    .results-header {
      display: none;
    }
    .summary-chat-now {
      display: none;
    }
    .summary-search-form {
      display: block;
      margin-bottom: 30px;
    }
    #results-container {
      #col-2 {
        float: right;
        width: 80%;
        .product-thumb__image {
          width: auto;
        }
        #product-results {
          margin-left: 30px;
          .quickshop-wrapper.active--2::before {
            left: 50%;
          }
          .product-grid__item {
            width: 50%;
          }
          .results-summary {
            display: none;
          }
          .results-header {
            .product-sort-container {
              display: none;
            }
            h3 {
              text-indent: 0;
            }
            #summary {
              text-align: left;
              font-family: 'Brandon Text Bold';
              font-size: 14px;
              letter-spacing: 0.24em;
              line-height: 19px;
              text-transform: uppercase;
            }
          }
        }
        #featured-products {
          float: left;
          width: 100%;
        }
      }
      #col-1 {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 2px;
        font-family: 'Brandon Text Bold';
        float: left;
        width: 20%;
        #breadcrumbs,
        #dimensions {
          margin-bottom: 20px;
        }
        #dimensions,
        #breadcrumbs,
        #recent-searches {
          h3 {
            text-transform: uppercase;
            padding: 10px 0px;
            margin: 0px 10px;
            font-weight: bold;
            margin-bottom: 0px;
            border-top: none;
            border-right: none;
            border-left: none;
            border-bottom: 1px solid #bfbfbf;
          }
          dl {
            margin-left: 10px;
            dd {
              margin-left: 0px;
              padding-bottom: 3px;
              .close {
                margin-left: 5px;
              }
              a {
                text-decoration: none;
                text-transform: uppercase;
                font-weight: bold;
              }
            }
            dt {
              cursor: pointer;
              margin-bottom: 5px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
    .pagination-container {
      margin-bottom: 20px;
      .pagination {
        .view-all.first > a {
          @include typography-label-text;
          border-bottom: 1px solid $color-core-black;
          font-size: 14px;
        }
        .page {
          display: none;
        }
        .next.last {
          display: none;
        }
      }
    }
    #bestseller-results {
      border-top: none;
      padding-top: 0;
      .results-header {
        border-top: 1px solid #d0d0d0;
        padding-top: 40px;
        .product-sort-container {
          display: none;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .section-esearch {
    .quickshop__container {
      .product__actions {
        .product__button {
          float: left;
          margin-top: 15px;
        }
        ul.product__inventory-status {
          clear: both;
        }
      }
    }
  }
}
// ie8 related CSs
.ie8 {
  .mpp {
    .mpp__content .product-grid--quickshop {
      .product-grid__content .quickshop-wrapper {
        .quickshop .product__details {
          padding: 22px;
        }
      }
    }
  }
  #col-2 {
    .product-grid.js-product-grid {
      .product-thumb {
        a.product-thumb__image-link div {
          display: block;
        }
        .product-thumb__cta {
          height: 30px;
        }
      }
      .product-grid__content .quickshop-wrapper {
        .quickshop .product__details {
          padding: 22px;
        }
      }
    }
  }
  .search-results__header {
    .search-form__fields {
      .search-form__results-count {
        padding-top: 9px;
      }
    }
  }
}
/* Live Chat */
.site-header {
  .lpStaticButton {
    width: auto;
    margin: 0px;
    td {
      border: none;
      padding: 0px;
    }
    .lpPoweredBy,
    .lpEmtStarRating {
      display: none;
    }
    a img {
      position: relative;
    }
  }
}
/* Rating */
.product__details {
  .product__rating {
    #BVRRSummaryContainer {
      width: 100%;
      margin-top: 7px;
      margin-bottom: 20px;
    }
    #BVQASummaryContainer {
      display: none;
    }
  }
}

.responsive-tabs {
  .resp-tabs-list {
    .bv_ask_tab {
      display: none;
    }
  }
}

.quickshop__container {
  .product--full {
    .product__rating {
      div.t-desktop {
        display: none;
      }
      span {
        div.t-desktop {
          display: inline;
        }
      }
    }
  }
}
/* footer design */
.site-footer-contact {
  .site-footer-contact__item.customerservice_width {
    width: 100%;
  }
  @media (min-width: 768px) {
    .site-footer-contact__item.customerservice_width {
      width: 50%;
      a {
        margin-left: 20%;
      }
    }
  }
}

.customer-service {
  .customer-service-quick-info {
    .customer-service-quick-info__list-item {
      margin-left: 14%;
    }
  }
}

.customer-service-section {
  #landing-email {
    background: none;
    border-bottom: 1px solid #d1d2d1;
  }
  #landing-faqs {
    border-right: 1px solid #d1d2d1;
    border-top: none;
  }
  #landing-call {
    border-bottom: 1px solid #d1d2d1;
  }
  @media (max-width: 768px) {
    .customer-service-landing-block {
      a {
        bottom: 40px;
      }
    }
  }
}

@media (min-width: 768px) {
  .homepage-hero.homepage-hero--light-arrows {
    top: -175px;
  }
}
