.enhanced-delivery-page {
  .column.right {
    #shipping-panel {
      background: $color-white;
      border-bottom: 1px solid $color-grey-border;
      margin-top: 0;
      padding: 0;
      #review-address-info {
        .shipping-panel__title {
          display: none;
        }
        .transaction-panel-details {
          margin: 0;
          padding: 0;
          .transaction-item {
            @include swap_direction(padding, 13px 10px 13px 13px);
            border-bottom: 1px solid $color-grey-border;
            font-size: 12px;
            margin: 0;
            width: 100%;
            dt {
              margin: 0;
              position: relative;
            }
            dd {
              padding: 16px 0 6px;
            }
            .checkout__subtitle {
              border: none;
              letter-spacing: 0.25px;
              margin: 0;
              padding: 0;
            }
            .minus {
              @include icon('minus');
            }
            .plus {
              @include icon('plus');
            }
            .accordion-link {
              #{$rdirection}: 0;
              color: $color-black-light;
              cursor: pointer;
              font-size: 14px;
              font-weight: 700;
              line-height: 1;
              position: absolute;
              top: 0;
              &.plus::before {
                font-weight: 700;
              }
            }
            .change_link {
              #{$ldirection}: 5px;
              background: url('/media/images/checkout/edit_icon.png') no-repeat;
              background-size: 18px;
              float: $rdirection;
              height: 20px;
              position: relative;
              text-indent: -9999px;
              top: 16px;
              width: 20px;
            }
            .checkout__subtitle .change_link {
              top: 34px;
            }
            &.collapsed .change_link {
              display: none;
            }
          }
        }
      }
    }
    .offer-code-panel {
      .error_messages {
        li {
          color: $color-red-shade;
          padding-bottom: 10px;
          &[id*='offer_criteria_met'] {
            color: $color-green-light;
          }
        }
      }
    }
  }
  .column.left {
    #checkout_complete {
      #payment-info-panel {
        #payment-type-cc,
        #payment-type-ncc {
          .payment-type {
            img {
              float: $rdirection;
              margin: -1px 15px;
              padding: 0 !important;
              position: static;
              width: 37px;
            }
            &--static {
              img {
                height: auto;
                margin-top: -4px;
              }
            }
            label {
              .text-label {
                position: relative;
                top: 1px;
              }
            }
            #form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_sofort_label,
            #form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_paypal_label,
            #form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_visa_label,
            #form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_mybank_label,
            #form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_cardlink_label {
              img {
                height: 23px;
                margin-top: 0;
                width: 37px;
              }
            }
            #form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_cardlink_label {
              img {
                height: 23px;
                margin-top: 0;
                width: auto;
              }
            }
            #form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_mybank_label {
              img {
                margin-top: 5px;
              }
            }
          }
          .payment-method {
            border-top: 1px solid $color-lighter-gray;
            margin-top: 50px;
          }
        }
      }
      input[type='radio'] ~ label::before {
        font-size: 20px;
        margin: 0 16px;
      }
      .wrapper {
        .error_messages {
          margin-bottom: 30px;
        }
        .legal_txt,
        .payment-other-terms {
          font-size: 12px;
          letter-spacing: 0.25px;
          margin-top: 15px;
          min-width: 100%;
          a {
            color: $color-black;
          }
          input[type='checkbox'] {
            #{$ldirection}: 0;
            appearance: none;
            border: 1px solid $color-grey-eclipse;
            border-radius: 2px;
            float: $ldirection;
            height: 18px;
            margin-#{$ldirection}: 17px;
            padding: 0;
            position: static;
            top: -3px;
            width: 18px;
            &:checked {
              background-color: $color-white;
              &::before {
                #{$ldirection}: 5px;
                border: solid $color-black;
                border-width: 0 2px 2px 0;
                content: '';
                display: block;
                height: 13px;
                position: relative;
                top: 0;
                transform: rotate(45deg);
                width: 6px;
              }
            }
          }
          label {
            bottom: 0;
            display: flex;
            margin-#{$ldirection}: 52px;
            position: relative;
            &::before {
              display: none;
            }
          }
        }
        #payment-info-panel {
          p,
          #review-instructions {
            display: none;
          }
          .shipping-panel__title {
            font-size: 16px;
            margin-bottom: 65px;
          }
          #payment-type-cc,
          #payment-type-ncc {
            border-top: 1px solid $color-lighter-gray;
            font-size: 12px;
            font-weight: bold;
            margin-bottom: 0;
            text-transform: uppercase;
            width: 100%;
            .payment-type {
              @include display-flex;
              border-bottom: 1px solid $color-lighter-gray;
              clear: both;
              float: none;
              height: 54px;
              margin-bottom: 0;
              min-height: 54px;
              outline: 0 !important;
              padding: 0;
              position: relative;
              width: 100%;
              label {
                height: 54px;
                margin-#{$ldirection}: 0;
                outline: 0;
                padding: 17px 0;
                width: 100%;
              }
            }
          }
          .cc-header {
            border-top: 1px solid $color-nobel;
            display: block;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0.25px;
            line-height: 1.2;
            margin: 0;
            padding: 19px 52px;
            text-transform: uppercase;
          }
          #payment-type-ncc {
            border-bottom: 1px solid $color-grey-border;
          }
        }
        .installopts {
          margin-top: -1px;
          position: relative;
          &::before {
            #{$rdirection}: 15px;
            border: 5px solid transparent;
            border-top-color: $color-gray-dark;
            content: '';
            display: block;
            pointer-events: none;
            position: absolute;
            top: 25px;
          }
          &::after {
            #{$ldirection}: 0;
            background: $color-nobel;
            content: '';
            height: 1px;
            position: absolute;
            top: 62px;
            width: 100%;
          }
          select {
            @include appearance(none);
            @include border-radius(0);
            background-image: none;
            border: 1px solid $color-gray-dark;
            color: $color-black;
            font-size: 16px;
            height: 54px;
            margin-top: 0;
            padding: 17px;
            width: 100%;
            option {
              color: $color-nobel;
            }
          }
        }
        .payment-description {
          background: $color-grey-standard;
          border: 1px solid $color-gray-dark;
          color: $color-gray-dark;
          float: none;
          font-size: 12px;
          height: auto;
          letter-spacing: 0.25px;
          line-height: 1.7;
          margin-top: 25px;
          padding: 24px 16px;
        }
        .viewcart-panel {
          display: none;
        }
        .checkout-buttons-container {
          @include box-shadow(none);
          clear: both;
          margin: 38px 0 60px;
          overflow: hidden;
          .checkout-buttons {
            @include box-shadow(none);
            #{$ldirection}: 0;
            #{$rdirection}: auto;
            background: $color-white;
            bottom: 0;
            display: block;
            height: auto;
            padding: 0;
            position: static;
            width: 100%;
            z-index: 102;
            .form-submit {
              @include border-radius(0);
              background-color: $color-black;
              float: $rdirection;
              font-size: 12px;
              font-weight: bold;
              height: 48px;
              letter-spacing: 1.25px;
              line-height: 1.5;
              margin: 0;
              position: relative;
              width: 348px;
            }
          }
        }
      }
    }
    .error_messages {
      [id*='offer_code'],
      [id*='offer_criteria'] {
        display: none;
      }
    }
  }
}
