$pp-small-down: $small-down;

@include breakpoint($pp-small-down) {
  #pickpoint_customer_info {
    .local-collection {
      .show-collection-point {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
    .set-collection-point {
      .local-collection-button {
        width: 100%;
      }
    }
  }
}

.click_and_collect_map {
  .overlay-content {
    .map-container {
      .search-box-cancel {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        margin: 10px 15px;
        width: 18px;
        height: 18px;
        color: #000;
        background: url('/media/images/background-images/background-image3.png') no-repeat 0 0;
      }
      .address-map-location-panel {
        left: 0;
        height: 550px;
        top: 0;
        width: 265px;
        padding-right: 0;
        display: inline-block;
        position: relative;
        margin: 65px 0 0 10px;
        overflow: hidden;
        @include breakpoint($medium-only) {
          display: block;
          float: left;
          width: 28%;
        }
        @include breakpoint($pp-small-down) {
          display: none;
          top: 9999px;
          z-index: 2;
          &.active {
            position: absolute;
            height: 100%;
            left: 0;
            top: 217px;
            margin: 56px 0 0 0;
            width: 100%;
            header {
              h2 {
                display: none;
              }
            }
          }
        }
        label {
          display: inline-block;
        }
        .close {
          display: none;
        }
        header,
        .messages {
          display: none;
        }
        .locations {
          background-color: #f3f3f3;
          height: 100%;
          .location-info-windows {
            height: 470px;
            overflow: auto;
          }
          @include breakpoint($pp-small-down) {
            padding-top: 40px;
            .location-info-windows {
              height: 100%;
            }
          }
        }
        .location {
          border-color: #666666;
          margin: 0;
          padding-top: 1em;
          &:first-child {
            padding-top: 20px;
          }
          .selected {
            border: 1px outset #666;
            padding: 1em 0.5em;
          }
          input {
            position: relative;
            float: left;
          }
          .service_provider,
          .title,
          .button,
          .address,
          .location_delivery_cost {
            margin-left: 20px;
            p {
              margin: 13px 0;
            }
          }
        }
      }
      .map-wrapper {
        height: 630px;
        width: 685px;
        vertical-align: top;
        display: inline-block;
        margin-top: 30px;
        @include breakpoint($medium-only) {
          height: 600px;
          width: 69%;
        }
        @include breakpoint($pp-small-down) {
          height: 100%;
          width: 100%;
          margin-top: 0;
        }
        .address-map-options-search-panel {
          position: relative;
          top: 0;
          left: 0;
          margin: 35px auto 10px;
          @include breakpoint($medium-only) {
            left: 12px;
            width: 100%;
          }
          .options-search-box-wrapper {
            .options-search-box {
              a {
                background: url(/media/images/global/icon-dropdown.png) no-repeat center right;
                background-color: #fafafa;
                border: 1px solid #6e6e6e;
                float: left;
                height: auto;
                letter-spacing: 0;
                line-height: normal;
                padding: 13px 12px;
                margin: 0 10px;
                max-width: 200px;
                width: 100%;
                .selectBox-arrow {
                  background-color: #fafafa;
                  line-height: 3.3;
                }
              }
              .select_metro {
                @include breakpoint($medium-only) {
                  display: inline;
                }
              }
              select {
                @include breakpoint($medium-only) {
                  height: 44px;
                  margin-right: 10px;
                  padding: 0 25px 0 10px;
                  width: 32%;
                }
                @include breakpoint($pp-small-down) {
                  background-color: #fafafa;
                  border: 1px solid #6e6e6e;
                  height: auto;
                  padding: 13px 12px;
                  margin: 0 10px;
                  text-align: center;
                  width: 100%;
                  max-width: 200px;
                  line-height: normal;
                }
              }
            }
          }
          .address-map-search-panel {
            float: none;
            position: relative;
            margin: 0 0 0 10px;
            display: inline-block;
            vertical-align: top;
            @include breakpoint($medium-only) {
              margin-left: 0;
              width: 28%;
            }
            select {
              background-color: #fafafa;
              border: 1px solid #6e6e6e;
              height: 29px;
              margin: 0 10px;
              text-align: center;
              width: 200px;
              vertical-align: bottom;
            }
            .change-date-container {
              margin-top: 8px;
            }
            @include breakpoint($pp-small-down) {
              position: relative;
              z-index: 1;
              margin: 0;
              width: 100%;
              max-width: none;
            }
          }
          @include breakpoint($pp-small-down) {
            margin: 35px 20px 10px 20px;
            .options-search-box-wrapper {
              .options-search-box {
                select {
                  height: auto;
                  margin: 0 0 20px 0;
                  width: 100%;
                  max-width: none;
                }
              }
            }
          }
        }
        .maps {
          width: 660px;
          height: 460px;
          margin: 25px 25px 30px 12px;
          position: relative;
          @include breakpoint($medium-only) {
            height: 475px;
            margin-top: 15px;
            width: 98%;
          }
          @include breakpoint($pp-small-down) {
            height: 100%;
            width: 100%;
            position: relative;
            margin: 0;
          }
          .maps-toggle-container {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
            span {
              line-height: 30px;
              padding: 8px 10px;
              border-radius: 0 0 5px 5px;
              color: #ffffff;
              background-color: #000000;
              border: 1px solid #000000;
              margin: 0 1px;
              &:hover,
              selected {
                border: 1px solid #000000;
                color: #000000;
                background-color: #ffffff;
              }
              &.location-toggle {
                display: none;
              }
            }
            @include breakpoint($pp-small-down) {
              position: absolute;
              text-align: right;
              margin: 0;
              top: 54px;
              span.location-toggle {
                display: inline;
              }
            }
          }
          .metro-map {
            .popup_container {
              position: absolute;
              padding: 14px 14px 9px;
              background-color: #f1f1f1;
              border: 1px solid #5d5e5c;
              border-radius: 6px;
              min-width: 100px;
              min-height: 100px;
              z-index: 101;
              top: 50px;
              .location {
                .statuspoint {
                  border-radius: 5px;
                  border: 1px solid #000;
                  width: 9px;
                  height: 9px;
                  position: absolute;
                  right: 0px;
                  top: 15px;
                }
                @include breakpoint($pp-small-down) {
                  .address {
                    p {
                      font-size: 15px;
                    }
                  }
                  .title {
                    h3 {
                      font-size: 18px;
                    }
                  }
                }
                .select-access-point {
                  margin-top: 5px;
                  display: table;
                }
              }
              .close {
                position: absolute;
                right: 5px;
                top: 5px;
                text-decoration: none;
                font-size: 14px;
                font-weight: bold;
                color: #666;
                z-index: 102;
                &:after {
                  content: '\274c';
                  font-size: 20px;
                  color: #d1d2d1;
                }
              }
              .atop {
                position: absolute;
                top: -12px;
                left: 50%;
                margin-left: -12px;
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 12px 12px 12px;
                border-color: transparent transparent #232323 transparent;
                &:after {
                  position: absolute;
                  display: block;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 0 11px 11px 11px;
                  border-color: transparent transparent #f1f1f1 transparent;
                  content: '';
                  top: 1px;
                  left: -11px;
                }
              }
              .abot {
                position: absolute;
                bottom: -12px;
                left: 50%;
                margin-left: -12px;
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 12px 12px 0 12px;
                border-color: #232323 transparent transparent transparent;
                &:after {
                  position: absolute;
                  display: block;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 11px 11px 0 11px;
                  border-color: #f1f1f1 transparent transparent transparent;
                  content: '';
                  top: -12px;
                  left: -11px;
                }
              }
              .itemspopuplist_one {
                width: 240px;
                height: 220px;
              }
              .itemspopuplist_two {
                width: 500px;
                height: 220px;
                .location_wrapper {
                  margin-right: 10px;
                  padding-right: 5px;
                  width: 240px;
                }
              }
              .andmore {
                height: 220px;
                width: 505px;
              }
              .location_wrapper {
                position: relative;
                display: inline-block;
                width: 240px;
                height: 220px;
                overflow: hidden;
                text-align: left;
              }
              .mCustomScrollBox {
                position: absolute;
                overflow: hidden;
                height: 90%;
                max-width: 100%;
                outline: none;
                direction: ltr;
                left: 0px;
                overflow: auto;
                padding: 0px 0px 0 14px;
              }
            }
          }
          .map-title {
            display: none;
            @include breakpoint($pp-small-down) {
              display: block;
            }
          }
        }
        .metro-map {
          height: 100%;
          width: 100%;
          background-color: #fff;
        }
        #metromap {
          height: 100%;
          width: 100%;
          position: relative;
          @include breakpoint($pp-small-down) {
            overflow: hidden;
          }
          .points,
          .customzoom {
            position: absolute;
            display: none;
          }
          .points {
            div.point_metro_img {
              position: absolute;
              @include breakpoint($pp-small-down) {
                .metro_image {
                  max-width: none;
                }
              }
            }
          }
          .metroPoint {
            position: absolute;
            width: 20px;
            height: 20px;
            background: url(//pickpoint.ru/select/design/metro_marker2.png) center center no-repeat;
            z-index: 1;
          }
        }
        #metrozoom {
          z-index: 101;
          position: absolute;
          left: 30px;
          top: 50px;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          .metroPoint {
            position: absolute;
            width: 20px;
            height: 20px;
            background: url(//pickpoint.ru/select/design/metro_marker2.png) center center no-repeat;
            z-index: 1;
          }
        }
        .map {
          .location {
            input[name='LOCATION_GROUP'] {
              display: none;
            }
            .statuspoint {
              .status_opened {
                background-color: #71a751;
                border: 1px solid #71a751;
              }
            }
            .button {
              .select-access-point {
                background-color: #fafafa;
                border: 1px solid #6e6e6e;
                color: #000000;
                cursor: pointer;
                height: auto;
                margin: 10px 0;
                padding: 7px 10px;
                text-align: center;
                text-decoration: none;
                width: 140px;
                &.disabled {
                  border: 1px solid #cccccc;
                  color: #cccccc;
                  cursor: default;
                }
              }
            }
            @include breakpoint($pp-small-down) {
              .title {
                h3 {
                  font-size: 12px;
                  border-bottom: 1px solid #333;
                }
              }
            }
          }
          .gm-style {
            .map-info-window {
              .location {
                input {
                  display: none;
                }
              }
            }
          }
        }
      }
      .map-title {
        font-size: 15px;
        font-weight: bold;
        margin: 0;
        padding: 20px;
        border-color: #666666;
        text-align: center;
        @include breakpoint($medium-only) {
          width: 100%;
        }
        @include breakpoint($pp-small-down) {
          font-size: 12px;
        }
      }
      &.map {
        .locations {
          .location-info-windows {
            display: block;
          }
        }
      }
      &.metro {
        .locations {
          .metro-list,
          .location-info-windows {
            display: none;
          }
        }
      }
      .locations {
        .messages {
          display: none;
          margin-top: 90px;
        }
        .metro-list {
          padding: 10px 20px 10px 10px;
          overflow-y: scroll;
          height: 470px;
          .metro-info {
            padding: 3px 0;
          }
          .metro-qty {
            float: right;
          }
          @include breakpoint($pp-small-down) {
            display: none;
            .metro-qty {
              margin-top: 0;
            }
          }
        }
        @include breakpoint($pp-small-down) {
          .location-info-windows {
            display: block;
            height: 100%;
          }
          &.metro-on {
            .metro-list {
              display: block;
              height: 100%;
            }
            .location-info-windows {
              display: none;
            }
          }
          .map-title {
            display: none;
          }
        }
      }
      .map-window-info {
        .title {
          margin-top: 10px;
          padding-right: 10px;
        }
        .address {
          margin: 6px 0;
        }
        h3 {
          font-size: 14px;
          letter-spacing: 0.03em;
        }
        p {
          margin: 0;
          letter-spacing: 0.045em;
        }
      }
      .map-window-more-info {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 202;
        background: #fff;
        border: 1px solid #dbdbdb;
        .content {
          padding: 0 20px 20px;
          overflow: auto;
          max-height: 595px;
          margin-top: 35px;
          @include breakpoint($pp-small-down) {
            max-height: 540px;
          }
          div {
            margin-bottom: 6px;
          }
          .button {
            display: inline-block;
          }
          p {
            margin-bottom: 0;
          }
          h3 {
            font-size: inherit;
          }
          .image {
            img {
              display: block;
              margin: 0 auto;
            }
          }
          button {
            overflow: hidden;
            .select-access-point {
              background-color: #fafafa;
              border: 1px solid #6e6e6e;
              color: #000000;
              cursor: pointer;
              height: auto;
              margin: 10px 0 0;
              padding: 7px 10px;
              text-align: center;
              text-decoration: none;
              width: 140px;
              &.disabled {
                border: 1px solid #cccccc;
                color: #cccccc;
                cursor: default;
              }
            }
          }
          .hint {
            display: inline-block;
            padding: 4px 14px;
            margin-bottom: 10px;
            border-radius: 5px;
          }
          .hint_green {
            background: #71a751;
            color: #ffffff;
          }
          .hint_red {
            background: #cc0000;
            color: #ffffff;
          }
        }
        .inner {
          position: relative;
          overflow: hidden;
          .close {
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 0;
            margin: 10px 15px;
            width: 18px;
            height: 18px;
            color: #000;
            background: url('/media/images/background-images/background-image3.png') no-repeat 0 0;
          }
        }
        @include breakpoint($pp-small-down) {
          background-color: white;
          margin-top: 0;
        }
      }
      .submit {
        bottom: 1px;
        left: 50%;
        margin-top: 20px;
        position: absolute;
        width: auto;
        z-index: 1;
        button {
          display: none;
        }
      }
    }
    .customzoom {
      .minus {
        position: absolute;
        width: 24px;
        height: 23px;
        background: url(//pickpoint.ru/select/design/zoomminus.gif) center center no-repeat;
        cursor: pointer;
        top: 115px;
      }
      .plus {
        position: absolute;
        width: 24px;
        height: 22px;
        background: url(//pickpoint.ru/select/design/zoomplus.gif) center center no-repeat;
        cursor: pointer;
      }
      .drag {
        position: absolute;
        width: 10px;
        height: 22px;
        background: url(//pickpoint.ru/select/design/zoomdrag.gif) center center no-repeat;
        cursor: pointer;
        left: 7px;
        top: 25px;
        z-index: 2;
      }
      .line {
        position: absolute;
        background: silver;
        width: 1px;
        height: 75px;
        top: 35px;
        left: 12px;
        z-index: 1;
      }
    }
    .map-container {
      .address-map-search-panel {
        display: none;
        position: inherit;
        z-index: 1;
        margin: 0;
        .search-box-toggle {
          height: 20px;
        }
        .status-bar-wrapper {
          position: fixed;
          bottom: 0;
          left: 50%;
          text-align: center;
          width: 30em;
          margin-left: -15em;
          z-index: 2;
          background-color: #000;
          .status-bar {
            .message {
              padding: 0.5em;
              border: none;
              box-shadow: 0px 1px 4px #333;
              &:first-child {
                border-radius: 0;
              }
              &:last-child {
                border-radius: 0;
              }
              .close {
                float: right;
                a {
                  text-decoration: none;
                  color: inherit;
                }
              }
              &.error {
                background: #ff0000;
                color: white;
              }
              &.warning {
                background: #f6e914;
                color: black;
              }
              &.success {
                background: white;
                border-color: black;
                color: black;
              }
              &.information {
                background: black;
                color: white;
              }
            }
          }
        }
        .search-box-field {
          width: 120px;
          margin-right: 60px;
          @include breakpoint($medium-only) {
            margin-right: 0;
            width: 100%;
          }
          &:focus {
            border-color: #6e6e6e;
          }
          @include breakpoint($pp-small-down) {
            float: left;
            width: 74%;
            padding: 5px;
          }
        }
        .search-box-submit {
          width: 60px;
          position: absolute;
          top: -1px;
          right: 0;
          line-height: normal;
          color: #fff;
          background-color: #000;
          padding: 17px 12px;
          cursor: pointer;
          @include breakpoint($medium-only) {
            right: 0;
          }
          &:hover {
            background-color: #ff4661;
          }
          span {
            display: block;
            margin-top: -2px;
          }
          @include breakpoint($pp-small-down) {
            width: 25%;
            margin-right: 0;
          }
        }
        .search-box {
          overflow: hidden;
          width: 100%;
          position: relative;
        }
      }
    }
  }
  .status_green {
    background-color: #0d9344;
  }
  .status_red {
    background-color: #be1d2c;
  }
  @include breakpoint($pp-small-down) {
    .group_location_radio {
      float: left;
      width: 25px;
    }
    .group_location_list {
      margin-left: 30px;
    }
    .metro_list_radio {
      float: left;
      width: 25px;
    }
  }
}

#map-foreground-node {
  margin-left: -483px;
  left: 50%;
  top: 50%;
  padding: 0;
  background-color: #f0f0f0;
  border-color: #dbdbdb;
  position: fixed;
  height: 630px;
  max-height: 630px;
  transform: translateY(-50%);
  z-index: 1002;
  .close-container {
    display: none;
  }
  @include breakpoint($pp-small-down) {
    left: 0;
    margin-left: 0;
    top: 0;
    transform: none;
    max-height: none;
    .overlay-content {
      height: 100%;
      .map-container {
        height: 100%;
        overflow: auto;
        position: relative;
      }
    }
  }
}

#map-overlay-background {
  left: 0;
  width: 100%;
  top: 0;
  bottom: 0;
  display: block;
  background-color: #000;
  opacity: 0.7;
  height: auto;
  z-index: 1000;
  position: fixed;
}

#delivery-options-group {
  .radio-wrapper {
    display: inline-block;
    width: 49%;
    vertical-align: top;
    @include breakpoint($pp-small-down) {
      label {
        margin-left: 5px;
        display: inline-block;
        position: relative;
        padding-left: 24px;
        &:before {
          left: 0;
          position: absolute;
          top: 3px;
        }
      }
    }
    .opt_desc {
      margin: 10px 20px;
      display: inline-block;
      @include breakpoint($pp-small-down) {
        margin: 10px 0 10px 27px;
      }
    }
  }
}

#pickpoint_customer_info,
.billing_method_section {
  .form_element {
    margin-bottom: 20px;
    input {
      margin-bottom: 0;
    }
  }
}

#pickpoint_customer_info {
  .personal-details {
    .input_address_1,
    .input_address_2,
    .input_address_3,
    .input_address_4,
    .input_postal_code {
      display: none;
    }
  }
  .local-collection-link {
    margin-bottom: 10px;
  }
}
