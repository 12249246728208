.change-password {
  .password-field {
    &__info {
      @include breakpoint($medium-up) {
        #{$ldirection}: 40%;
        top: 33%;
        width: 190px;
        margin-#{$ldirection}: 3%;
      }
      &:before {
        @include breakpoint($medium-up) {
          #{$ldirection}: auto;
          #{$rdirection}: 100%;
          -ms-transform: rotate(0);
          -webkit-transform: rotate(0);
          transform: rotate(0);
        }
      }
    }
  }
}

.change-password-gnav {
  .cms-password-field,
  .password-field {
    &__info {
      margin-#{$ldirection}: -1%;
      margin-top: -5px;
      width: 187px;
      &::before {
        top: 18%;
        #{$rdirection}: 70%;
      }
    }
  }
}
