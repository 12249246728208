$medium-range-max: max-width 769px;
$medium-range-min: min-width 641px;
$large-range-max: max-width 1024px;

.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: right;
}

.cleft {
  clear: left;
}

.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

select::-ms-expand {
  display: none;
}

.site-email-sms-signup {
  padding: 35px 90px 100px 90px;
  .site-email-sms-signup__header {
    h1 {
      font-size: 2.2em;
      height: auto;
    }
  }
  .site-email-sms-signup__field {
    width: 257px;
  }
}

.site-content {
  .customer-service-section {
    #contact-about-content {
      .customer-service-contact-form__categories {
        li {
          padding: 1em 13px;
          letter-spacing: 1.4px;
          &#category-service {
            padding: 1em 9px;
          }
        }
      }
    }
  }
  #dimensions {
    @include breakpoint($tablet-down) {
      .results {
        .results-header {
          span {
            word-break: break-all;
          }
        }
      }
    }
  }
  .store-locator--desktop {
    @include breakpoint($tablet-down) {
      .store-locator__directions-location {
        .directions-form__submit {
          margin-left: 0;
        }
      }
    }
  }
}

.site-footer__main {
  .site-email-signup__field {
    width: 48%;
  }
}

#results-container {
  .endeca-product-result {
    .product-thumb {
      &.product-thumb--animate-cta {
        .product__button--add-to-bag {
          display: none !important;
        }
      }
    }
  }
}

.appt-book {
  #appointment-book-sections {
    .appointment-select {
      .appointments-container {
        .confirm-container {
          width: 62%;
          .total-time {
            width: 55%;
          }
        }
        ul.error li {
          color: #a81010;
          margin: 0.5em 0;
          font-weight: bold;
        }
      }
    }
    .appt-book-section {
      .service-select {
        .service {
          .service-details {
            h3.service-title {
              margin-top: -2px;
            }
          }
        }
      }
    }
    .confirmation-container,
    .appointment-details {
      .date-time {
        width: 100%;
        border-right: none;
      }
      .artist {
        display: none;
      }
    }
  }
}

@include breakpoint($medium-range-max) {
  .appointments-container {
    .selects-container {
      .counter-select-container {
        select.location-select {
          width: 100%;
        }
      }
    }
  }
  .location-select-section {
    .appt-book-location-selector {
      .location-select {
        width: 55%;
        float: left;
        margin-left: 10%;
      }
      .location-submit {
        float: left;
        margin-left: 20px;
      }
    }
  }
  .appt-book {
    #appointment-book-sections {
      .appointment-select {
        .appointments-container {
          .confirm-container {
            width: 81%;
          }
        }
      }
    }
  }
}

.appt-book {
  .appt-book-sections-container {
    .appt-book-section {
      #appt-book-my-appointments-no-appts {
        .no-appts-content {
          font-size: 18px;
        }
      }
    }
  }
}
