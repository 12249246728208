.enhanced-delivery-page {
  .hidden-enh-delivery,
  .site-header__nav,
  .site-utils,
  div.site-utils__promo {
    display: none;
  }
  .site-header div.site-logo {
    margin-#{$ldirection}: 0;
    max-width: 294px;
    padding: 27px 35px;
  }
  .error_messages.error li {
    margin-#{$ldirection}: 0;
  }
}
