.change-password {
  .profile-password-update {
    &__action {
      .form-submit {
        &:disabled,
        &.disabled {
          color: $color-light-gray;
          background-color: $color-white;
          cursor: not-allowed;
          border: 1px solid $color-gray-light;
        }
      }
    }
  }
  .password-field {
    &__info {
      @include breakpoint($medium-up) {
        #{$ldirection}: 100%;
        top: 33%;
        width: 190px;
        margin-#{$ldirection}: 3%;
      }
      &-reset {
        @include breakpoint($medium-up) {
          top: 50%;
          #{$ldirection}: 31%;
          width: 190px;
        }
        @include breakpoint($medium-portrait-only) {
          #{$ldirection}: 29%;
        }
        @include breakpoint($ipad-pro-portrait-only) {
          #{$ldirection}: 20%;
        }
      }
      &-confirm {
        margin-#{$ldirection}: 2%;
        @include breakpoint($medium-up) {
          width: 190px;
          -webkit-transform: translateY(-30%);
          -moz-transform: translateY(-30%);
          -o-transform: translateY(-30%);
          transform: translateY(-30%);
        }
      }
      &:before {
        @include breakpoint($medium-up) {
          #{$ldirection}: auto;
          #{$rdirection}: 100%;
          -ms-transform: rotate(0);
          -webkit-transform: rotate(0);
          transform: rotate(0);
        }
      }
    }
  }
  &-gnav {
    .cms-password-field {
      &__info {
        margin-#{$ldirection}: 43%;
        margin-top: -27px;
        &:before {
          top: 32%;
          right: 22%;
        }
      }
    }
  }
}
