.viewcart-paypal-button {
  text-align: $ldirection;
  .paypal-button {
    float: $ldirection;
    margin-#{$ldirection}: 170px;
    position: absolute;
    @include breakpoint($large-tablet-up) {
      margin-top: 56px;
      margin-#{$ldirection}: 0px;
    }
    @include breakpoint($medium-down) {
      margin-top: 20px;
      position: static;
    }
  }
  .terms-conditions {
    float: $ldirection;
    margin-top: 10px;
    input {
      position: inherit;
      float: $ldirection;
      margin-#{$ldirection}: 172px;
      margin-top: 25px;
      @include breakpoint($large-tablet-up) {
        margin-#{$ldirection}: 0px;
        margin-top: 72px;
      }
      @include breakpoint($medium-down) {
        margin-top: 15px;
      }
    }
    &-txt {
      width: 40%;
      margin-#{$ldirection}: 190px;
      margin-top: 23px;
      @include breakpoint($large-tablet-up) {
        margin-#{$ldirection}: 0px;
        width: 100%;
        margin-top: 72px;
      }
      @include breakpoint($medium-down) {
        margin-top: 15px;
      }
    }
    .error {
      color: $color-red;
    }
  }
}

.day-of-birth {
  &__collection-field {
    .selectbox {
      &:hover {
        color: $color-black;
      }
    }
  }
}

.customer-service-contact-form {
  & &__submit,
  .button {
    &[disabled] {
      background-color: $color-gray;
      cursor: not-allowed;
    }
  }
}

@include breakpoint($medium-portrait-only) {
  .js-sticky-header {
    .site-header {
      .site-utils {
        &--sticky {
          .site-utils {
            &__links {
              height: 100px;
              .site-nav {
                &--sticky {
                  width: 90%;
                }
              }
            }
          }
          .site-header {
            &__menu-list {
              text-align: center;
            }
          }
        }
      }
    }
  }
  .site-header {
    &__menu-list {
      padding-#{$ldirection}: 134px;
    }
  }
}