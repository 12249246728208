.viewcart {
  .newsletter-info {
    &__header {
      text-indent: 0;
      margin: 20px 0 10px;
    }
    &__fieldset {
      margin-bottom: 20px;
    }
    &__text--new,
    &__text--provid {
      margin-left: 0;
    }
    &__item {
      margin-left: 0;
      padding-left: 0;
    }
  }
  .payments-icon {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    margin: 10px auto 0;
    .logo {
      background-position: center;
      background-size: 100%;
      margin: 0 7px;
      max-width: 40px;
      width: 100%;
      height: 20px;
    }
  }
}
