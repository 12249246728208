#lpChat {
  .lp_actions_bar_container {
    .lp_actions_bar {
      .lp_action_item {
        .lp_action_wrapper {
          .lp_title {
            text-transform: none;
          }
        }
      }
    }
  }
}

.touch .device-mobile {
  #lpChat .lp_lpview_content select {
    -moz-appearance: menulist;
    -webkit-appearance: menulist;
    appearance: menulist;
  }
}

.site-utils__links {
  .site-utils__menu {
    .site-utils__item--live-chat {
      display: inline-block;
    }
  }
}
