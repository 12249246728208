.password-sent {
  &__text {
    .sent-info__text {
      margin: 0px 0px 10px 20px;
    }
  }
}

.password-reset {
  &__item {
    input[type='text'],
    input[type='password'] {
      display: block;
      width: 300px;
    }
  }
}

.account-page {
  &__content {
    .newsletter_text {
      margin-bottom: 0;
    }
    .section-header:before {
      background: none;
    }
    .account-payment {
      display: none;
    }
    .account-address {
      width: 100%;
      .section-header {
        width: 100%;
      }
    }
  }
  h2,
  h3 {
    font-weight: bold !important;
  }
  .account-profile {
    span {
      font-weight: bold;
    }
  }
  .account-orders {
    span {
      font-weight: bold;
    }
  }
}

#address_form_container {
  .address-overlay {
    &-billing,
    &-shipping {
      input[type='text'] {
        width: 100%;
      }
      .selectbox {
        width: 100%;
        margin-bottom: 20px;
      }
      .form_element {
        margin-bottom: 15px;
      }
      .default_ship_container {
        position: relative;
      }
    }
  }
}

.address-form {
  &__content {
    .ac_list {
      height: 150px;
      overflow-y: scroll;
    }
  }
}

.payment-info {
  display: none;
  &__header {
    display: none;
  }
}

.sms_promo_container {
  margin: 0 0 0 10px;
  padding: 10px;
  float: left;
  width: 100%;
}

.order-details-page {
  .order-products {
    .cart-item__desc {
      margin-right: 15px;
    }
    .cart-item__price {
      width: 21%;
    }
    .cart-item__total {
      float: right;
      text-align: right;
    }
    .cart-header {
      .cart-header__item {
        font-weight: bold;
      }
    }
  }
  .order-details {
    .order-details__item {
      .checkout__subtitle {
        font-weight: bold;
      }
    }
  }
}

.order-totals__section td {
  text-align: right;
}

#order_detail {
  .order-info {
    .order-info__item:nth-child(1) {
      a {
        color: inherit !important;
        text-decoration: none;
        pointer-events: none;
      }
    }
  }
}

.account-utilities {
  .sidebar-menu {
    .profile-pic-main {
      #profile-pic-img {
        #profile-pic-name {
          word-wrap: break-word;
        }
      }
      #profile-pic-wrapper {
        #profile_pic_overlay {
          display: none;
        }
      }
    }
    .sidebar-menu__item {
      a {
        font-weight: bold;
      }
    }
  }
}

.favorites-page__content {
  @include breakpoint($medium-range-max) {
    @include breakpoint($medium-range-min) {
      margin-right: 5px;
    }
  }
  .favorites-page__head {
    .favorites-page__header {
      font-weight: bold;
      @include breakpoint($medium-range-max) {
        @include breakpoint($medium-range-min) {
          letter-spacing: 0.02em;
        }
      }
    }
    .favorites-page__tabs {
      .favorites-page__tab {
        font-weight: bold;
        @include breakpoint($medium-range-max) {
          @include breakpoint($medium-range-min) {
            padding: 8px 20px;
          }
        }
      }
    }
  }
  .favorites {
    .my-lists__list-actions {
      #share_ {
        display: none;
      }
    }
    .favorites__list {
      .favorite-actions {
        @include breakpoint($medium-range-max) {
          @include breakpoint($medium-range-min) {
            top: 70px;
          }
        }
      }
    }
  }
  .favorites-mylists__content {
    .favorites-mylists__lists {
      .my-lists__list-actions {
        margin-top: 15px;
        .my-lists__list-link--share {
          display: none;
        }
      }
      .my-lists {
        .my-lists__list-content {
          .favorite-actions {
            @include breakpoint($medium-range-max) {
              @include breakpoint($medium-range-min) {
                top: 75px;
              }
            }
          }
        }
      }
    }
  }
  .favorites__recommended-product {
    .product_name {
      min-height: 115px;
    }
    .formatted_price {
      .shade {
        letter-spacing: 0px;
        .swatch-container {
          margin-right: 4px;
        }
      }
    }
  }
}

#cboxLoadedContent {
  .create-wishlist__form {
    .create-wishlist__name {
      width: 65%;
    }
  }
  .profile-pic-upload {
    &__help {
      ul {
        display: none;
      }
    }
  }
}

.account__new-account {
  input[type='text'] {
    margin-bottom: 20px;
  }
}

.order-details-page {
  .order-products {
    .cart-item:last-child {
      border-bottom: 1px solid $color-gray-border;
    }
  }
}

.profile-page {
  .profile-page__header {
    font-weight: bold;
  }
  #profile_preferences {
    h3 {
      font-weight: bold;
    }
    .privacy-policy {
      .privacy-policy__fieldset {
        padding: 10px;
        margin-bottom: 30px;
      }
    }
  }
}

.past-purchases-page {
  .past-purchases__content {
    .past-purchases-data-header {
      li {
        font-weight: bold;
      }
      .trans-id {
        @include breakpoint($medium-range-max) {
          @include breakpoint($medium-range-min) {
            width: 20%;
          }
        }
      }
      .price {
        @include breakpoint($medium-range-max) {
          @include breakpoint($medium-range-min) {
            width: 17%;
          }
        }
      }
      .order-status {
        @include breakpoint($medium-range-max) {
          @include breakpoint($medium-range-min) {
            width: 35%;
          }
        }
      }
    }
    .past-purchases-data-item {
      .trans-id {
        @include breakpoint($medium-range-max) {
          @include breakpoint($medium-range-min) {
            width: 21%;
          }
        }
      }
      .price {
        @include breakpoint($medium-range-max) {
          @include breakpoint($medium-range-min) {
            width: 18%;
          }
        }
      }
      .order-status {
        @include breakpoint($medium-range-max) {
          @include breakpoint($medium-range-min) {
            width: 25%;
          }
        }
      }
      .view-details {
        @include breakpoint($medium-range-max) {
          @include breakpoint($medium-range-min) {
            width: 7%;
          }
        }
      }
    }
  }
}

.address-book-page {
  h3,
  h2 {
    font-weight: bold !important;
  }
  .address-book {
    .section-head {
      font-weight: bold;
    }
  }
}

.address.fs {
  .city_container,
  .address1_container {
    position: relative;
    p#shipping-note {
      display: none;
    }
    .ac_list {
      position: absolute;
      height: auto;
      width: 100%;
      border: 1px solid #999999;
      background-color: #ffffff;
      overflow: hidden;
      z-index: 20;
      ul {
        width: 100%;
        overflow: hidden;
        overflow-y: auto;
        overflow-x: hidden;
        height: auto;
        max-height: 300px;
        list-style-position: outside;
        list-style: none;
        padding: 0 !important;
        margin: 0 !important;
        li {
          margin: 0px;
          padding: 2px 5px !important;
          cursor: pointer;
          display: block;
          overflow: hidden;
          border-bottom: 1px solid #999999;
          color: #333333;
        }
      }
    }
  }
}

#colorbox {
  #cboxClose {
    width: 16px;
  }
}

.account {
  .profile-page.account__section {
    .profile-page__content {
      .newsletter-info {
        &__text--new {
          margin-left: 10px;
        }
        &__item {
          margin: 0px;
        }
        &__text--provid {
          margin-left: 10px;
        }
      }
      .privacy-policy {
        &__required {
          display: none;
        }
      }
    }
  }
}

.site-email-sms-signup {
  $self: &;
  #{$self}__agree {
    input {
      position: absolute;
    }
  }
}
