/**
 * @file emea_base_pc.scss
 */
$color-gray-bglight: #fbfbfb;
$color-gray-bgmedium: #eff1f0;
$color-gray-bglightest: #fdfdfd;

@import 'sections/checkout/guest_checkout_signin-panel';
@import 'sections/all';
@import 'sections/checkout/checkout_viewcart';
@import 'sections/pc/account-reset-password';
@import 'sections/checkout/_co_emea_update_checkout.scss';
@import 'sections/checkout/enhanced-delivery';
@import 'sections/shared';
