#signin {
  .info-content {
    display: none;
  }
}

.enhanced-delivery-page {
  #checkout_shipping {
    #emailpromo-info-panel {
      .h_newsletter {
        display: none;
      }
      .newsletter-info__item,
      .sms_promo_container {
        .sms_options_container {
          padding-top: 10px;
          .required_mark {
            display: none;
          }
          .address-form-name {
            top: 18px;
            &.active {
              top: -10px;
            }
          }
        }
        input[type='checkbox'] {
          #{$ldirection}: 0;
          appearance: none;
          border: 1px solid $color-grey-eclipse;
          border-radius: 2px;
          float: $ldirection;
          height: 18px;
          padding: 0;
          position: relative;
          top: 3px;
          width: 18px;
          &:checked {
            background-color: $color-white;
            &::before {
              #{$ldirection}: 5px;
              border: solid $color-black;
              border-width: 0 2px 2px 0;
              content: '';
              display: block;
              height: 13px;
              position: relative;
              transform: rotate(45deg);
              width: 6px;
            }
          }
        }
        label {
          display: flex;
          padding-#{$ldirection}: 11px;
          &::before {
            display: none;
          }
        }
      }
      .newsletter-info {
        border-top: 1px solid $color-black;
        border-bottom: 1px solid $color-black;
        margin-top: 15px;
        max-width: 560px;
        position: relative;
        &__header {
          @include swap_direction(margin, 12px 10px 13px 15px);
          border: none;
          letter-spacing: 0.25px;
          padding: 0;
        }
        .newsletter-toggle {
          font-size: 12px;
          letter-spacing: 0.25px;
          margin: 0 15px;
          .newsletter-info {
            &__item,
            &__text--new,
            &__fieldset {
              margin: 0;
            }
            &__text--provid {
              margin-bottom: 12px;
            }
            &__item {
              padding: 10px 0;
            }
          }
          .info-content {
            margin: 0 0 20px;
          }
          .sms_promotions {
            .newsletter-info__header {
              display: none;
            }
            .sms_promo_container {
              margin-top: 14px;
              .form_element {
                padding: 0;
                &.phone_container {
                  padding-bottom: 14px;
                }
              }
            }
          }
        }
        .news-letter-icon {
          #{$ldirection}: 94%;
          color: $color-black;
          cursor: pointer;
          display: inline-block;
          font-size: 14px;
          font-weight: bold;
          position: absolute;
          top: 10px;
        }
        .hide {
          @include icon('minus');
        }
        .show {
          @include icon('plus');
          &::before {
            font-weight: bold;
          }
        }
      }
    }
  }
  &#signin {
    .info-content {
      display: block;
    }
  }
}
