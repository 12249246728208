/// Promo panel top
.promo-panel {
  &.loyalty-panel {
    .banner {
      @include breakpoint($medium-up) {
        padding-left: 150px;
        background-color: $color-gray-background;
        background-image: url('/media/export/images/loyalty/BB_SimplifiedCrest_Black_103.png');
        background-repeat: no-repeat;
        background-position: 20px 5px;
        min-height: 120px;
      }
    }
  }
  .loyalty-title {
    @include h24;
    font-size: 21px;
    line-height: 25px;
    margin-bottom: 5px;
  }
  .loyalty-paragraph {
    margin-bottom: 0;
    a {
      margin-top: 20px;
      @include l2;
    }
  }
  &.no-top-border .banner {
    border-top: none;
  }
}
$offers-width: 700px;

#cboxContent {
  .offers-everyday,
  .offers-hero,
  .offers-details,
  .offers-vip,
  .offer-specific__container {
    max-width: $offers-width;
  }
  .offers-hero {
    padding: 24px 6px 30px 6px;
    p {
      margin: 0 0 10px 0;
    }
    &__header {
      margin: 2px 0 30px 0;
      h3 {
        border-bottom: 0px;
        &.inner {
          padding: 10px 7px 0 9px;
        }
      }
    }
    &__button {
      margin: 20px 0 0 0px;
      line-height: 1.25em;
    }
    &__title {
      font-size: 27px;
      line-height: 36px;
    }
    &__text {
      line-height: 36px;
      font-size: 16px;
    }
    ul {
      li {
        line-height: 24px;
      }
    }
    &__actions {
      margin-top: 10px;
    }
  }
  .offers-everyday {
    padding: 10px 10px 9px 10px;
    min-height: 230px;
    &__items {
      display: block;
    }
    &__item {
      width: 33%;
      &:last-child {
        .offers-everyday__item-inner {
          border-right: 0px;
        }
      }
    }
    &__item-inner {
      min-height: 164px;
    }
  }
  .offers-details-item {
    &__image-container,
    &__link-container {
      width: 30%;
    }
    &__description,
    &__button-container {
      width: 70%;
    }
  }
}

.offer-code-panel {
  .offer_code_form_container {
    em#one-offer-only span {
      display: inline;
      float: right;
    }
  }
}
