.enhanced-delivery-page {
  .column.left {
    #checkout_complete {
      .wrapper {
        #payment-info-panel {
          .shipping-panel__title {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
  .pg_wrapper {
    .column.left {
      .selectBox-arrow {
        &::before {
          #{$rdirection}: 15px;
          border: 5px solid transparent;
          border-top-color: $color-grey-shadow;
          bottom: 18px;
          content: '';
          pointer-events: none;
          position: absolute;
          z-index: 999;
        }
      }
      #pickpoint_customer_info {
        .shipping-title {
          &.packstation-title {
            display: none;
          }
        }
      }
      .shipping-title {
        font-size: 16px !important;
      }
      #delivery-options {
        display: none;
      }
      #checkout_shipping {
        #shipping-address-info-panel {
          #shipping-info-panel {
            .address_controls {
              #choose-address {
                &.new-addressform-expand {
                  border-bottom: 0;
                }
              }
            }
          }
        }
      }
      #checkout_complete {
        .wrapper {
          .checkout-title {
            .shipping-panel__title {
              font-size: 36px !important;
            }
          }
          #shipping-address-info-panel,
          #checkout_shipping {
            .form_element {
              text-transform: capitalize;
            }
            .shipping-address-info {
              .edit-address-container {
                .country_container {
                  background: $color-white;
                  border: 0;
                  padding: 0;
                  &::before {
                    #{$rdirection}: 25px;
                    border: 5px solid transparent;
                    border-top-color: $color-black;
                    content: '';
                    pointer-events: none;
                    position: absolute;
                    top: 25px;
                  }
                }
              }
              .address_controls {
                border: 0;
                margin: 0;
              }
              #address_form_container,
              .address-form__content {
                .country_container {
                  background: $color-white;
                  border: 0;
                  padding: 0;
                  a.selectBox {
                    @include swap_direction(padding, 17px 20px 13px 15px);
                    background: $color-white;
                    color: $color-nobel;
                    height: 54px;
                    letter-spacing: 0;
                    width: 100%;
                    &::before {
                      #{$rdirection}: 15px;
                      border: 5px solid transparent;
                      border-top-color: $color-black;
                      content: '';
                      pointer-events: none;
                      position: absolute;
                      top: 25px;
                    }
                    .selectBox-arrow {
                      display: none;
                    }
                  }
                }
              }
              #address_controls {
                display: none;
              }
            }
          }
          #payment-info-panel {
            .opt_desc {
              display: block;
            }
            #payment-type-cc {
              .payment-type {
                height: auto;
                .payment-group-options-cc {
                  margin: 0;
                  padding: 9px 0 10px;
                  .payment-item-wrapper {
                    margin-bottom: 0;
                    padding-#{$rdirection}: 12px;
                    label {
                      height: auto;
                      padding: 0;
                    }
                    .payment-icon {
                      height: 23px;
                      margin: 6px 3px;
                    }
                  }
                }
              }
            }
            .payment-group-options-cod {
              padding-top: 15px;
            }
            .error_messages {
              font-size: 12px;
              font-weight: bold;
            }
          }
        }
      }
      .wrapper {
        #checkout_shipping {
          #delivery-method-options {
            .home_delivery_option,
            .click_and_collect_option {
              height: 96px;
              width: 269px;
            }
            .home_delivery_option {
              margin-#{$rdirection}: 22px;
            }
          }
          .shipping-address-info {
            .edit-address-container,
            .shipping-address-form-container {
              .country_container {
                background: $color-white;
                border: 0;
                padding: 0;
                position: relative;
                &::before {
                  #{$rdirection}: 15px;
                  border: 5px solid transparent;
                  border-top-color: $color-grey-shadow;
                  content: '';
                  pointer-events: none;
                  position: absolute;
                  top: 25px;
                }
              }
            }
          }
          #pickpoint_customer_info {
            .country {
              background: $color-light-brown;
              border: 1px solid $color-black;
              color: $color-black;
              height: 54px;
              margin-top: 22px;
              max-width: 560px;
              padding: 18px 15px;
            }
          }
          #address_form_container.address_form_container {
            .form_element.country_container {
              border: 0;
              color: $color-nobel;
              padding: 0;
              .selectBox-dropdown {
                color: $color-black;
              }
            }
          }
          .error_messages {
            li {
              margin-bottom: 12px;
            }
          }
          #billing-address-info-panel #billing_address_form_container,
          .shipping-address-info #address_form_container {
            border-bottom: none;
          }
          .privacy-policy__fieldset {
            display: none !important;
            margin-top: 20px;
            .privacy-policy__item {
              position: relative;
              input[type='checkbox'] {
                & ~ label,
                & ~ .label-class {
                  float: $ldirection;
                  margin-#{$ldirection}: 35px;
                  &::before {
                    display: none;
                  }
                  &::after {
                    #{$ldirection}: 0;
                    border: 1px solid $color-grey-eclipse;
                    border-radius: 2px;
                    content: '';
                    float: $ldirection;
                    height: 18px;
                    padding: 0;
                    position: absolute;
                    top: 3px;
                    width: 18px;
                  }
                }
                &:checked {
                  & ~ label,
                  & ~ .label-class {
                    &::before {
                      #{$ldirection}: 6px;
                      border: solid $color-black;
                      border-width: 0 2px 2px 0;
                      content: '';
                      display: block;
                      height: 13px;
                      position: absolute;
                      top: 3px;
                      transform: rotate(45deg);
                      width: 6px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .column.right {
    &.checkout__sidebar {
      #links-panel {
        .content {
          .link {
            color: $color-grey-dark;
          }
        }
      }
      .order-summary-panel {
        .order-summary-table {
          .shipping-container {
            .shipping-msg {
              text-transform: none;
            }
          }
        }
      }
      .viewcart-items {
        .cart-item__desc-container {
          .price.column {
            .disc_perc {
              flex: 0 0 34%;
            }
          }
          .cart-item__product-subline {
            display: none;
          }
        }
      }
    }
    #shipping-panel {
      #review-address-info {
        .transaction-panel-details {
          .transaction-item {
            .change_link {
              top: 15px;
            }
            &.delivery-container {
              .checkout__subtitle {
                a {
                  #{$ldirection}: 5px;
                  background: url('/media/images/checkout/edit_icon.png') no-repeat;
                  background-size: 18px;
                  float: $rdirection;
                  height: 20px;
                  position: relative;
                  text-indent: -9999px;
                  top: 34px;
                  width: 20px;
                }
              }
              &.collapsed {
                a {
                  display: none;
                }
              }
            }
            &.promocode-container,
            &.promo-code {
              display: none;
            }
          }
        }
      }
    }
    #links-panel {
      background: $color-grey-light;
    }
    h2.checkout__panel-title {
      font-size: 14px !important;
    }
  }
  .ship-method-group-label {
    margin-bottom: 42px;
    input[type='radio'] {
      #{$ldirection}: 0;
      position: absolute;
    }
    .radio-wrapper {
      label {
        &::before {
          content: '';
          opacity: 0;
        }
      }
    }
    .click_and_collect_option {
      margin-#{$rdirection}: 0;
    }
    .home_delivery_option,
    .click_and_collect_option {
      display: block;
      padding-#{$ldirection}: 0;
      text-align: center;
      &::after {
        #{$ldirection}: 45%;
      }
    }
  }
  #delivery-options-group {
    .radio-wrapper {
      .opt_desc {
        display: none;
      }
    }
  }
  h2.checkout__panel-title {
    border-bottom: 0;
  }
  .checkout {
    #emailpromo-info-panel {
      float: none;
      margin-bottom: 22px;
      .news-letter-icon {
        pointer-events: none;
      }
    }
    .checkout-buttons-container {
      margin-top: 0;
    }
  }
  .set-collection-point {
    a.local-collection-button {
      float: $rdirection;
      font-size: 12px;
      line-height: 2.34;
      margin: 36px 0 22px;
      padding: 10px;
      text-align: center;
      width: 348px;
    }
  }
}
