.enhanced-delivery-page {
  #checkout_shipping {
    #loyalty-info-panel {
      border-top: 1px solid $color-black;
      border-bottom: 1px solid $color-black;
      margin-top: 16px;
      max-width: 560px;
      position: relative;
      .loyalty-info-panel__title {
        @include swap_direction(margin, 10px 10px 10px 15px);
        border: none;
        letter-spacing: 0.25px;
        padding: 0;
      }
      .loyalty-toggle {
        margin: 0 15px;
        #create_loyalty_account_container {
          font-size: 12px;
          letter-spacing: 0.25px;
        }
        p {
          margin: 0;
        }
      }
      .loyalty-icon {
        #{$ldirection}: 94%;
        color: $color-black;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        position: absolute;
        top: 10px;
      }
      .hide {
        @include icon('minus');
      }
      .show {
        @include icon('plus');
        &::before {
          font-weight: bold;
        }
      }
      .create-loyalty-account {
        input[type='checkbox'] {
          @include appearance(none);
          @include border-radius(2px);
          #{$ldirection}: 0;
          border: 1px solid $color-grey-eclipse;
          float: $ldirection;
          height: 18px;
          padding: 0;
          position: relative;
          top: 1px;
          width: 18px;
          &:checked {
            background-color: $color-white;
            &::before {
              @include rotate(45deg);
              #{$ldirection}: 4px;
              border: solid $color-black;
              border-width: 0 2px 2px 0;
              content: '';
              display: block;
              height: 13px;
              position: relative;
              width: 6px;
            }
          }
        }
        label {
          @include display-flex;
          padding-#{$ldirection}: 15px;
          &::before {
            display: none;
          }
        }
      }
    }
  }
}
